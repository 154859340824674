export enum TYPES_LAITERIE {
    CA = 'CA',
    CC = 'CC',
    UD = 'UD'
}

export const TYPES_LAITERIE_LABEL: {[key: string]: string} = {
    [TYPES_LAITERIE.CA]: 'Centre d’Appartenance',
    [TYPES_LAITERIE.CC]: 'Centre de Collecte',
    [TYPES_LAITERIE.UD]: 'Usine de Dépotage'
};

export const TYPES_LAITERIE_OPTIONS = Object.entries(TYPES_LAITERIE_LABEL).map(([value, label]) => ({
    value, label
}))