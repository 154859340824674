import { default as jwt_decode } from 'jwt-decode';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb';
import CustomComponent from '../../components/CustomComponent';
import { DefaultLayout } from '../../components/Layouts';
import Pagination from '../../components/Pagination';
import { LimitValuesSelect, SortbyColumnButton } from '../../components/TableComponents';
import { DebounceQueue } from '../../helpers/debouncer';
import { dateFormat, hasPermission } from '../../helpers/helpers';


class SimulateProfil extends CustomComponent {
    private _isMounted = false;
    private queue = new DebounceQueue()

    constructor(props: any) {
        super(props);
        this.state = {
            filter: {
                userName: "",
                entiteName: "",
                siret: "",
                entiteClasse: "",
                habilitation: ""
            },
            profils: [],
            currentPage: 0,
            pageTotal: 1,
            sortField: "",
            sortDirection: "",
            limit: 10
        }
    }

    componentDidMount() {
        this._isMounted = true;
        document.title = 'Infolabo | Simuler un profil';
        if (this.checkIsConnectedPWA() && hasPermission('DRT_SIMULER_PROFIL') && this._isMounted) {
            this.getProfils();
        }
        else {
            this.props.history.push('/');
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getProfils = async (triggeredByPagination?: boolean) => {
        const url = "/per_personne/profils";
        const method = "POST";
        let page = this.state.currentPage || 0;
        const body = {
            limit: parseInt(this.state.limit),
            page: triggeredByPagination ? page : 0,
            ...(this.state.filter.userName && { name: this.state.filter.userName }),
            ...(this.state.filter.entiteName && { entiteName: this.state.filter.entiteName }),
            ...(this.state.filter.siret && { siret: this.state.filter.siret }),
            ...(this.state.filter.entiteClasse && { entiteClasse: this.state.filter.entiteClasse }),
            ...(this.state.filter.habilitation && { habilitation: this.state.filter.habilitation }),
            ...(this.state.sortField && { orderBy: this.state.sortField }),
            ...(this.state.sortDirection && { ordering: this.state.sortDirection }),
        };
        const data = await this.request(url, method, body);
        if (data?.per_personnes) {
            this._isMounted && this.setState({
                profils: data.per_personnes,
                pageTotal: data.paginate.totalPages,
                currentPage: (data.paginate.currentPage + 1) > data.paginate.totalPages ? 0 : data.paginate.currentPage
            }, () => { if ((data.paginate.currentPage + 1) > data.paginate.totalPages) this.getProfils(triggeredByPagination); });
        }
    }

    simulate_profil = async (simulate_user_id: number, simulate_per_habilitation: number) => {
        const url = "/auth/simulate_profil";
        const method = "POST";
        const body = { simulate_user_id: simulate_user_id, simulate_per_habilitation: simulate_per_habilitation };
        const data = await this.request(url, method, body);
        if (data && 'access_token' in data) {
            const session: any = jwt_decode(data.access_token);
            localStorage.setItem('session', JSON.stringify(session));
            localStorage.setItem('access_token', data.access_token);
            localStorage.setItem('refresh_token', data.refresh_token);
            localStorage.setItem('per_habilitations', JSON.stringify(data.per_habilitations));
            localStorage.setItem('per_personne', JSON.stringify(data.per_personne));
            localStorage.setItem('habilitation_id', JSON.stringify(data.habilitation_id));
            localStorage.setItem('habDroit', JSON.stringify(data.habDroit));
            this.props.history.push('/');
            this.props.history.go(0);
        }
    }

    paginationOnClick = (e: any) => {
        const value = e.currentTarget.value;
        this.setState({
            currentPage: (value - 1),
        }, () => this.queue.push(this.getProfils, [true], true))
    }

    handleSort = (field: any) => {
        this.setState({
            sortField: field,
            sortDirection: (field !== this.state.sortField || this.state.sortDirection === 'DESC') ? 'ASC' : 'DESC'
        }, () => this.queue.push(this.getProfils, [], true))
    }

    render() {
        return (
            <DefaultLayout>
                <div className="container">
                    <BreadCrumb crumbs={[{ name: 'Simuler un profil', path: '#' }]} />
                    <h1 className="main-title">Simuler un profil</h1>
                    <LimitValuesSelect
                        default_value={this.state.limit}
                        steps={[10, 20, 50]}
                        onChange={(e: any) => this.setState({ limit: e.target.value }, () => this.getProfils(true))}
                    />
                    <div className="table-responsive desktop-only">
                        <table className="table table-custom">
                            <thead>
                                <tr>
                                    <th>
                                        <div className="d-flex">
                                            <span className="sr-only">Nom de l'utilisateur</span>
                                            <input
                                                className="table-input"
                                                type="text"
                                                placeholder="Nom de l'utilisateur"
                                                name="userName"
                                                onInput={(e: any) => this.handleInputChange(e, null, 'filter', () => this.queue.push(this.getProfils))}
                                            />
                                            <SortbyColumnButton active={this.state.sortField === 'name'} direction={this.state.sortDirection} onClick={() => this.handleSort('name')} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className="d-flex">
                                            <span className="sr-only">Nom de l'entité</span>
                                            <input
                                                className="table-input"
                                                type="text"
                                                placeholder="Nom de l'entité"
                                                name="entiteName"
                                                onInput={(e: any) => this.handleInputChange(e, null, 'filter', () => this.queue.push(this.getProfils))}
                                            />
                                            <SortbyColumnButton active={this.state.sortField === 'nom_usuel'} direction={this.state.sortDirection} onClick={() => this.handleSort('nom_usuel')} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className="d-flex">
                                            <span className="sr-only">N° SIRET</span>
                                            <input
                                                className="table-input"
                                                type="text"
                                                placeholder="N° SIRET"
                                                name="siret"
                                                onInput={(e: any) => this.handleInputChange(e, null, 'filter', () => this.queue.push(this.getProfils))}
                                            />
                                            <SortbyColumnButton active={this.state.sortField === 'siret'} direction={this.state.sortDirection} onClick={() => this.handleSort('siret')} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className="d-flex">
                                            <span className="sr-only">Classe entité</span>
                                            <input
                                                className="table-input"
                                                type="text"
                                                placeholder="Classe entité"
                                                name="entiteClasse"
                                                onInput={(e: any) => this.handleInputChange(e, null, 'filter', () => this.queue.push(this.getProfils))}
                                            />
                                            <SortbyColumnButton active={this.state.sortField === 'lib_classe'} direction={this.state.sortDirection} onClick={() => this.handleSort('lib_classe')} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className="d-flex">
                                            <span className="sr-only">Habilitation</span>
                                            <input
                                                className="table-input"
                                                type="text"
                                                placeholder="Habilitation"
                                                name="habilitation"
                                                onInput={(e: any) => this.handleInputChange(e, null, 'filter', () => this.queue.push(this.getProfils))}
                                            />
                                            <SortbyColumnButton active={this.state.sortField === 'lib_hab'} direction={this.state.sortDirection} onClick={() => this.handleSort('lib_hab')} />
                                        </div>
                                    </th>
                                    <th>Dernière connexion</th>
                                    <th className="table-actions">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.profils.map((profil: any, index: number) => (
                                        <tr key={index}>
                                            <td>{[profil.nom, profil.prenom].filter((n: any) => !!n).join(' ')}</td>
                                            <td>{profil.nom_usuel}</td>
                                            <td>{profil.siret}</td>
                                            <td>{profil.lib_classe}</td>
                                            <td>{profil.lib_hab}</td>
                                            <td>{profil.dt_der_cnx && dateFormat(profil.dt_der_cnx, 'DD-MM-YYYY hh:mm')}</td>
                                            <td className="table-custom__action">
                                                <button
                                                    data-tip="Simuler le profil"
                                                    className="action-btn"
                                                    data-toggle="tooltip"
                                                    data-placement="left"
                                                    title="Simuler le profil"
                                                    onClick={() => this.simulate_profil(profil.user_id, profil.per_hab_id)}
                                                >
                                                    <i className="icon-simulate"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="table-card mobile-only">
                        {
                            this.state.profils.map((profil: any, index: number) => (
                                <div className="table-card-item" key={index}>
                                    <div className="table-card-wrap">
                                        <Dropdown className="table-card-dropdown">
                                            <Dropdown.Toggle as={CustomToggle}>
                                                <span className="sr-only">Ouvrir les actions</span>
                                                <i className="icon-actions"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => this.simulate_profil(profil.user_id, profil.per_hab_id)}>Selectionner l'entité</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <dl className="table-card-data">
                                            <dt>Nom de l'utilisateur</dt><dd>{profil.nom + " " + profil.prenom}</dd>
                                            <dt>Nom de l'entité</dt><dd>{profil.nom_usuel}</dd>
                                            <dt>N° SIRET</dt><dd>{profil.ent_code_pays}{profil.siren}{profil.nic}</dd>
                                            <dt>Classe entité</dt><dd>{profil.lib_classe}</dd>
                                            <dt>Habilitation</dt><dd>{profil.lib_hab}</dd>
                                            <dt>Dernière connexion</dt><dd>{profil.dt_der_cnx}</dd>
                                        </dl>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    {(this.state.pageTotal > 1 && this.state.profils.length > 0) && (
                        <Pagination handleClick={this.paginationOnClick} pageCurrent={(this.state.currentPage + 1)} pageTotal={this.state.pageTotal} pageDisplay={3} />
                    )}

                </div>
            </DefaultLayout>
        )
    }
}


const CustomToggle = React.forwardRef<HTMLButtonElement, any>(({ children, onClick }: any, ref) => (
    <button
        className="btn-icon btn-icon--white"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </button>
));

export default withRouter(SimulateProfil);
