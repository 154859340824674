import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { AnnuaireHeader } from '../_common/AnnuaireHeader';
import { AnnuaireTabs } from '../_common/AnnuaireTabs';
import CustomComponent from '../../../components/CustomComponent';
import { FilterDropdown } from '../../../components/FilterDropdown';
import { FormInput, FormInputSelect, FormInputSelectCreatable } from '../../../components/FormComponents';
import { DefaultLayout } from '../../../components/Layouts';
import Pagination from '../../../components/Pagination';
import { LimitValuesSelect, SortbyColumnButton, TableNested } from '../../../components/TableComponents';
import { hasPermission } from '../../../helpers/helpers';
import { exportCSVAnnuaire } from '../../../requests/exportCSVAnnuaire';
import { DeleteModal } from './_deleteModal';
import GroupeFavorisActions from "./_groupeFavoriActions";
import ManageGroupesFavorisModal from "./_manageGroupesFavorisModal";
import { DebounceQueue } from '../../../helpers/debouncer';

class ListEntite extends CustomComponent {
    private _isMounted = false;
    private queue = new DebounceQueue();

    private default_filters = {
        orderBy: 'nom_usuel',
        ordering: 'ASC',
        nom_usuel: '',
        siret: '',
        num_ede: '',
        adr_lig_1: '',
        ville: '',
        code_postal: '',
        groupe_favoris: '',
        classe_entite: '',
    }

    constructor(props: any) {
        super(props);
        this.state = {
            entites: [],
            filters: this.default_filters,
            recherches: [],
            groupes_favoris: [],
            options: {
                classe_entites: [],
                villes: [],
                habilitations: [],
                groupes: [],
                entites: [],
                groupes_favoris: [],
            },
            paginate: {
                count: 0,
                limit: 10,
                page: 0,
                pageTotal: 0,
            },
            strict_filters: ['code_postal'],
            deleteTarget: null,
            ville: '',
            checked: [],
        }
    }

    componentDidMount() {
        this._isMounted = true;
        document.title = 'Infolabo | Liste des entités';
        this.getEntites()
        this.getClasseEntiteOptions()
        this.getRecherches()
        hasPermission('DRT_ANNU_ENT_GRP_FAVORIS') && this.getGroupesFavoris()
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getEntites = async (triggeredByPagination: boolean = false) => {
        const params = new URLSearchParams()

        Object.keys(this.state.paginate).forEach(key => {
            const val = this.state.paginate[key]
            if (val !== '') params.append(key, val)
        })

        Object.keys(this.state.filters).forEach(key => {
            const val = this.state.filters[key]
            if (val !== '') params.append(key, val)
        })

        if (!triggeredByPagination) {
            params.set('page', '0');
            this.setState({checked: []});
        }

        const data = await this.request(`/ent_entite?${params.toString()}`, 'GET');

        if (data && this._isMounted) {
            this.setState((prev: any) => ({
                entites: data.entites,
                paginate: {
                    ...prev.paginate,
                    ...data.paginate
                }
            }))
        }
    }

    getGroupesFavoris = async () => {
        const data = await this.request(`/per_groupe_favori`, 'GET');

        if (data?.statusCode === 200 && this._isMounted) {
            this.setState((prev: any) => ({
                groupes_favoris: data.groupes_favoris,
                options: {
                    ...prev.options,
                    groupes_favoris: data.groupes_favoris.map((gfa: any) => ({label: gfa.nom, value: gfa.id})),
                }
            }));
        }
    }

    groupeFavorisDeleteCallback = (gfa_id: number) => {
        this.setState((prev: any) => ({
            groupes_favoris: prev.groupes_favoris.filter((gfa: any) => gfa.id !== gfa_id),
            options: {
                ...prev.options,
                groupes_favoris: prev.options.groupes_favoris.filter((option: any) => option.value !== gfa_id),
            }
        }));
        if (+this.state.filters.groupe_favoris === gfa_id) this.getEntites();
    }

    getClasseEntiteOptions = async () => {
        const classe_entites = await this.request('/ref_classe_entite/autocomplete', 'GET')
        if (classe_entites && this._isMounted) {
            this.setState((prev: any) => ({
                options: {
                    ...prev.options,
                    classe_entites
                }
            }))
        }
    }

    getRecherches = async () => {
        const recherches = await this.request(`/inf_recherche/ANNUAIRE_LISTE_ENTITE`, 'GET')

        if (recherches && this._isMounted) {
            this.setState({recherches})
        }
    }

    paginationOnClick = (e: any) => {
        const value = e.currentTarget.value;
        this.setState((prev: any) => ({
            paginate: {
                ...prev.paginate,
                page: (value - 1),
            }
        }), () => this.queue.push(this.getEntites, [true]))
    }

    handleSort(field: string) {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                orderBy: field,
                ordering: (field !== prev.filters.orderBy || prev.filters.ordering === 'DESC') ? 'ASC' : 'DESC'
            }
        }), () => this.queue.push(this.getEntites))
    }

    toggleChildren = (id: number) => {
        this.setState((prevState: any) => ({
            ...prevState,
            entites: prevState.entites.map((per: any) => {
                if (per.id === id) {
                    return {
                        ...per,
                        showChildren: !per.showChildren
                    }
                }
                return per
            })
        }))
    }

    async getData(url: string) {
        let result = [];
        const method = "GET";
        const data = await this.request(url, method);
        if (data && data.length > 0) {
            result = data;
        }
        return result;
    }

    async getCitiesByLib(lib: any) {
        let cities_name: { value: string, label: string }[] = [];
        let cities = await this.getData('/ref_commune/findByLib/' + lib.data);

        cities.forEach((city: any) => {
            cities_name.push({ value: city.lib_commune, label: `${city.lib_commune} (${city.departement.code_departement})` });
        });
        this.setState((prev: any) => ({
            options: {
                ...prev.options,
                villes: cities_name
            }
        }));
    }

    exportToCSV = async () => {
        this.setState({isLoading: true})
        await exportCSVAnnuaire('LISTE_ENTITES', this.state.filters)
        this.setState({isLoading: false})
    }

    isAllChecked = () => {
        return !this.state.entites.find((ent: any) => !this.state.checked.includes(ent.id));
    }

    handleCheckAll = (e: any) => {
        const {checked} = e.target;
        this.setState((prev: any) => ({
            checked: checked ?
                [...prev.checked, ...prev.entites.filter((ent: any) => !prev.checked.includes(ent.id)).map((ent: any) => ent.id)]
                : prev.checked.filter((id: number) => !prev.entites.find((ent: any) => ent.id === id))
        }));
    }

    handleCheck = (e: any) => {
        const {checked, value} = e.target;
        this.setState((prev: any) => ({
            checked: checked ?
                [...prev.checked, +value]
                : prev.checked.filter((id: number) => id !== +value),
        }));
    }

    render() {
        return (
            <>
                <DefaultLayout>
                    <div className="container">
                        <AnnuaireHeader />
                        <div className="row align-items-end">
                            <div className="col-lg-9 order-1 order-lg-0">
                                <AnnuaireTabs />
                            </div>
                            <div className="col-lg-3 order-0 order-lg-1">
                                <LimitValuesSelect
                                    default_value={this.state.paginate.limit}
                                    steps={[10, 20, 50]}
                                    onChange={(e: any) => this.setState((prev: any) => ({ paginate: {...prev.paginate, limit: +e.target.value, page: 0 }}), this.getEntites)}
                                />
                            </div>
                        </div>

                        <FilterDropdown
                            parent={this}
                            type_recherche='ANNUAIRE_LISTE_ENTITE'
                            onExport={hasPermission('DRT_ANNU_ENT_EXPORTER') ? {csv: this.exportToCSV} : undefined}
                            filters={this.state.filters}
                            recherches={this.state.recherches}
                            onUpdate={this.getRecherches}
                            onSearchLoad={(filters: any) => this.setState({filters}, () => this.queue.push(this.getEntites, [], true))}
                            onFiltersSearch={() => this.queue.push(this.getEntites, [], true)}
                            onFiltersReset={() => {this.setState({filters: this.default_filters}, () => this.queue.push(this.getEntites, [], true))}}
                        >
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group form-group--inline">
                                        <FormInput
                                            id="nom_usuel"
                                            name="nom_usuel"
                                            type="text"
                                            label="Nom usuel"
                                            handle={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getEntites))}
                                            value={this.state.filters.nom_usuel} />
                                    </div>
                                    <div className="form-group form-group--inline">
                                        <FormInput
                                            id="siret"
                                            name="siret"
                                            type="text"
                                            label="N° SIRET"
                                            handle={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getEntites))}
                                            value={this.state.filters.siret} />
                                    </div>
                                    <div className="form-group form-group--inline">
                                        <FormInput
                                            id="num_ede"
                                            name="num_ede"
                                            type="text"
                                            label="N° EDE"
                                            handle={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getEntites))}
                                            value={this.state.filters.num_ede} />
                                    </div>
                                    <div className="form-group form-group--inline">
                                        <FormInput
                                            id="code_postal"
                                            name="code_postal"
                                            type="text"
                                            label="Code postal"
                                            handle={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getEntites))}
                                            value={this.state.filters.code_postal} />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group form-group--inline">
                                        <FormInputSelectCreatable
                                            optionsName="villes"
                                            options={this.state.options.villes.sort((a: any, b: any) => (a.label > b.label) ? 1 : -1).map((ville: any) => ({ value: ville.value, label: ville.label }))}
                                            label="Ville"
                                            name="ville"
                                            id="ville"
                                            displayValueNotLabel
                                            handle={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getEntites))}
                                            handleCreateOption={(e: any) => this.setState((prev: any) => ({
                                                filters: {...prev.filters, ville: e.data.value},
                                                options: {
                                                    ...prev.options,
                                                    villes: [...prev.options.villes, {label: e.data.value, value: e.data.value}]
                                                }
                                            }), () => this.queue.push(this.getEntites))}
                                            handleInput={(e: any) => {
                                                this.setState({ ville: e.data?.value });
                                                this.getCitiesByLib(e);
                                            }}
                                            value={this.state.filters.ville}
                                            placeholder=""
                                            isDropdownDisabled={
                                                (this.state.filters?.ville == null || this.state.filters?.ville === "") &&
                                                this.state.options.villes.length < 1 &&
                                                this.state.ville === ""
                                            }
                                            maxLength={100}
                                            isClearable={true}
                                        />
                                    </div>
                                    <div>
                                        <div className="form-group form-group--inline">
                                            <FormInputSelect
                                                options={this.state.options.groupes_favoris}
                                                label="Groupe de favoris"
                                                name="groupe_favoris"
                                                id="groupe_favoris"
                                                handle={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getEntites))}
                                                value={this.state.filters?.groupe_favoris || ""}
                                                isClearable={true}
                                            />
                                        </div>
                                        {!!this.state.groupes_favoris.length && (
                                            <ManageGroupesFavorisModal
                                                groupes_favoris={this.state.groupes_favoris}
                                                callback={this.groupeFavorisDeleteCallback}
                                            />
                                        )}
                                    </div>
                                    <div className="form-group form-group--inline">
                                        <FormInputSelect
                                            options={this.state.options.classe_entites}
                                            label="Classe d’entité"
                                            name="classe_entite"
                                            id="classe_entite"
                                            handle={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getEntites))}
                                            value={this.state.filters.classe_entite}
                                            isClearable={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </FilterDropdown>

                        {(hasPermission('DRT_ANNU_ENT_GRP_FAVORIS') && !!this.state.checked?.length) && (
                            <GroupeFavorisActions
                                checked={this.state.checked}
                                gfaOptions={this.state.options.groupes_favoris}
                                callback={() => {
                                    this.setState({checked: []});
                                    this.getGroupesFavoris();
                                }} />
                        )}
                        <div className="table-responsive table-card-mobile">
                            <table className="table table-custom">
                                <thead>
                                    <tr>
                                        <th className="table-check">
                                            <input
                                                type="checkbox"
                                                className="checkbox checkbox--white"
                                                checked={this.isAllChecked()}
                                                onChange={this.handleCheckAll}
                                            />
                                        </th>
                                        <th></th>
                                        <th>
                                            <div className="d-flex">
                                                <span className="sr-only">Nom usuel</span>
                                                <input
                                                    value={this.state.filters.nom_usuel}
                                                    onChange={(e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getEntites))}
                                                    name="nom_usuel"
                                                    type="text"
                                                    className="table-input"
                                                    placeholder='Nom usuel' />
                                                <SortbyColumnButton active={this.state.filters.orderBy === 'nom_usuel'} direction={this.state.filters.ordering} onClick={() => this.handleSort('nom_usuel')} />
                                            </div>
                                        </th>
                                        <th>
                                            <div className="d-flex">
                                                <span className="sr-only">N° SIRET</span>
                                                <input
                                                    value={this.state.filters.siret}
                                                    onChange={(e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getEntites))}
                                                    name="siret"
                                                    type="text"
                                                    className="table-input"
                                                    placeholder='N° SIRET' />
                                                <SortbyColumnButton active={this.state.filters.orderBy === 'siret'} direction={this.state.filters.ordering} onClick={() => this.handleSort('siret')} />
                                            </div>
                                        </th>
                                        <th>
                                            <div className="d-flex">
                                                <span className="sr-only">Adresse</span>
                                                <input
                                                    value={this.state.filters.adr_lig_1}
                                                    onChange={(e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getEntites))}
                                                    name="adr_lig_1"
                                                    type="text"
                                                    className="table-input"
                                                    placeholder='Adresse' />
                                                <SortbyColumnButton active={this.state.filters.orderBy === 'adr_lig_1'} direction={this.state.filters.ordering} onClick={() => this.handleSort('adr_lig_1')} />
                                            </div>
                                        </th>
                                        <th>
                                            <div className="d-flex">
                                                <span className="sr-only">Ville</span>
                                                <input
                                                    value={this.state.filters.ville}
                                                    onChange={(e: any) => {
                                                        this.handleInputChange(e, null, 'filters', this.queue.push(this.getEntites));
                                                        this.setState({ villes: e.target?.value ? [{ label: e.target?.value, value: e.target?.value }] : [], ville: e.target?.value });
                                                    }}
                                                    name="ville"
                                                    type="text"
                                                    className="table-input"
                                                    placeholder='Ville' />
                                                <SortbyColumnButton active={this.state.filters.orderBy === 'ville'} direction={this.state.filters.ordering} onClick={() => this.handleSort('ville')} />
                                            </div>
                                        </th>
                                        <th>
                                            <div className="d-flex">
                                                <span className="sr-only">Code postal</span>
                                                <input
                                                    value={this.state.filters.code_postal}
                                                    onChange={(e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getEntites))}
                                                    name="code_postal"
                                                    type="text"
                                                    className="table-input"
                                                    placeholder='Code postal' />
                                                <SortbyColumnButton active={this.state.filters.orderBy === 'code_postal'} direction={this.state.filters.ordering} onClick={() => this.handleSort('code_postal')} />
                                            </div>
                                        </th>
                                        <th className="table-actions">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.entites.map((entite: any) => (<React.Fragment key={entite.id}>
                                        <tr>
                                            <td className="table-check">
                                                <input
                                                    type="checkbox"
                                                    className="checkbox"
                                                    checked={this.state.checked?.includes(entite.id)}
                                                    value={entite.id}
                                                    id={`entite${entite.id}`}
                                                    onChange={this.handleCheck}
                                                />
                                            </td>
                                            <td className='table-icon'>
                                                {(entite.personnes?.length > 0 || entite.groupes?.length > 0) ?
                                                    <button
                                                        onClick={() => this.toggleChildren(+entite.id)}
                                                        aria-expanded={entite.showChildren}
                                                        className="action-btn action-btn--collapse">
                                                        <i className="icon-city"></i>
                                                    </button>

                                                    :
                                                    <span className="action-btn"><i className="icon-city"></i></span>
                                                }
                                            </td>
                                            <td data-label="Nom usuel" >{entite.nom_usuel}</td>
                                            <td data-label="N° SIRET">{entite.siret}</td>
                                            <td data-label="Adresse">{entite.adr_lig_1}</td>
                                            <td data-label="Ville">{entite.ville}</td>
                                            <td data-label="Code postal">{entite.code_postal}</td>
                                            <td className='table-actions'>
                                                <div className="d-flex desktop-only">
                                                    {(hasPermission('DRT_ANNU_ENT_CONSULTER') && entite.isReadable) && <Link className='action-btn' to={`/annuaire/entite/${entite.id}`}><i className="icon-eye"></i></Link>}
                                                    {(hasPermission('DRT_ANNU_ENT_GERER') && entite.isEditable) && <Link className='action-btn' to={`/annuaire/entite/${entite.id}/modifier`}><i className="icon-pen"></i></Link>}
                                                    {(hasPermission('DRT_ANNU_ENT_GERER') && entite.isEditable) && <button className='action-btn' onClick={() => this.setState({ deleteTarget: entite})}><i className="icon-trash"></i></button>}
                                                </div>
                                                <Dropdown className="mobile-only">
                                                    <Dropdown.Toggle as={CustomToggle}>
                                                        <span className="sr-only">Ouvrir les actions</span>
                                                        <i className="icon-actions"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {(hasPermission('DRT_ANNU_ENT_CONSULTER') && entite.isReadable) && <Link className='dropdown-item' to={`/annuaire/entite/${entite.id}`}>Consulter</Link>}
                                                        {(hasPermission('DRT_ANNU_ENT_GERER') && entite.isEditable) && <Link className='dropdown-item' to={`/annuaire/entite/${entite.id}/modifier`}>Editer</Link>}
                                                        {(hasPermission('DRT_ANNU_ENT_GERER') && entite.isEditable) && <button className='dropdown-item' onClick={() => this.setState({ deleteTarget: entite})}>Supprimer</button>}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                        {entite.personnes && entite.personnes?.length > 0 && (
                                            <TableNested
                                                colspan={8}
                                                seeAllLink={`/annuaire/entite/${entite.id}`}
                                                total={entite.nombrePersonnes}
                                                data={entite.personnes?.map((e: any) => ({ nom: e.nom, prenom: e.prenom, email: e.email_1, ville: e.ville, departement: e.code_postal, id: e.id, canShow: e.canShow, canUpdate: e.canUpdate, canDelete: e.canDelete }))}
                                                id={entite.id}
                                                table="per_personne"
                                                limit={entite.limitNested}
                                                show={entite.showChildren}
                                                canShow
                                                canUpdate
                                            />
                                        )}
                                        {!!entite.groupes?.length && (
                                            <TableNested
                                                colspan={8}
                                                seeAllLink={`/annuraire/entite/${entite.id}`}
                                                total={entite.nombreGroupes}
                                                data={entite.groupes?.map((e: any) => ({nom: e.nom, description: e.description, proprietaire: `${e.proprietaire?.siret} - ${e.proprietaire?.nom_usuel}`, id: e.id}))}
                                                id={entite.id}
                                                table="group"
                                                limit={entite.limitNested}
                                                show={entite.showChildren}
                                                canShow
                                                canUpdate
                                            />
                                        )}
                                    </React.Fragment>))}
                                </tbody>
                            </table>
                            {this.state.entites.length === 0 && <>Aucune donnée annuaire à afficher</>}
                        </div>

                        <Pagination handleClick={this.paginationOnClick} pageCurrent={(this.state.paginate.page + 1)} pageTotal={this.state.paginate.pageTotal} pageDisplay={3} />
                    </div>
                </DefaultLayout>
                <DeleteModal
                    entite={this.state.deleteTarget}
                    onDelete={() => this.setState({deleteTarget: null}, () => {
                        this.getEntites();
                        this.getGroupesFavoris();
                    })}
                    onClose={() => this.setState({deleteTarget: null})} />
            </>
        )
    }
}

const CustomToggle = React.forwardRef<HTMLButtonElement, any>(({ children, onClick }: any, ref) => (
    <button
        className="btn-icon btn-icon--white"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </button>
));

export default withTranslation()(withRouter(ListEntite));
