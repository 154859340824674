import { NavLink } from "react-router-dom"
import BreadCrumb from "../../components/BreadCrumb"
import { hasPermission } from "../../helpers/helpers"

const LINKS = [
    { name: 'CNIEL', slug: 'cniel', permissions: ['DRT_AIDE_CNIEL'] },
    { name: 'Laboratoire', slug: 'laboratoire', permissions: ['DRT_AIDE_LABORATOIRE'] },
    { name: 'Laiterie', slug: 'laiterie', permissions: ['DRT_AIDE_LAITERIE'] },
    { name: 'Producteur', slug: 'producteur', permissions: ['DRT_AIDE_PRODUCTEUR'] },
]

type Props = {
    onHome?: boolean
    homeTab?: string
};

export const AideListHeader = ({ onHome, homeTab }: Props) => {

    return (<>
        {!onHome && <BreadCrumb crumbs={[{ name: 'Aide' }]} />}
        <h1 className="main-title">Aide</h1>
        <ul className="nav nav-tabs m-t-10 m-t-md-0 m-r-md-15">
            {LINKS.map(tab =>
                !tab.permissions || (tab.permissions && hasPermission(tab.permissions)) ?
                    <li key={tab.slug} className="nav-item">
                        <NavLink className="nav-link" to={!!onHome && homeTab?.toLocaleLowerCase() === tab.slug ? '' : `/aide/${tab.slug}`}>
                            {tab.name}
                        </NavLink>
                    </li>
                    :
                    <></>
            )}
        </ul>
    </>
    )
}