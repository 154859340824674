import classNames from 'classnames';
import DATALIST from "../../../components/DataList";
import Modal from "../../../components/Modal";
import TABLE from "../../../components/Table";
import {dateFormat, exists, formatSiretNomUsuel, numberFormat, Request} from '../../../helpers/helpers';
import saveAs from "file-saver";

interface DetailsModalProps {
    echDetails?: any,
    onClose: Function
}

export const DetailsModal = ({ echDetails, onClose }: DetailsModalProps) => {

    const {ech, code_cc, resultats} = echDetails;
    const type_lait = `${ech?.esp_id?.libelle} - ${ech?.nat_id?.lib_court} ${ech?.tank_code ? " (Tank #" + ech?.tank_code + ")" : ""}`;
    const statut_echantillon = `${ech?.ste_id?.libelle}`;
    const litrage = (ech?.volume_collecte && ech?.uni_id_vol_collecte?.libelle) ? `${ech?.volume_collecte} ${ech?.uni_id_vol_collecte?.libelle}` : '-';
    const signataire = (resultats && resultats.length > 0)
        ? `${resultats[0]?.sig_id?.nom || '-'} - ${resultats[0]?.sig_id?.fonction || '-'}`
        : '-';

    const onExport = async () => {
        const tye_code = echDetails.ech.tye_id?.code;
        const ech_id = echDetails.ech.id;
        const url: string = `/exports/echantillon/${ech_id}`;
        const date_prelevement = dateFormat(echDetails.ech.date_prelevement, 'YYYYMMDD');
        let fileName: string = '';

        if (tye_code === 'PRD') {
            const prd_siret = echDetails.ech.prd_id.ent_id.siret;
            fileName = `${prd_siret}_DETAIL_ECHANTILLON_R_${date_prelevement}.pdf`;
        }
        else if (tye_code === 'CIT') {
            const ent_id_cc_siret = echDetails.ech.ent_id_cc.siret;
            fileName = `${ent_id_cc_siret}_DETAIL_ECHANTILLON_R_${date_prelevement}.pdf`;
        }

        const blob: Blob = await Request(url, 'GET', undefined, false, false, true);
        if (!!blob && blob instanceof Blob) {
            saveAs(blob, fileName);
        }
    }

    return (
        <Modal customLayout variant="data" open size="lg" centered={false} text_button="Fermer" onClose={onClose}>
            <Modal.Header>
                <div className="d-flex align-items-center m-b-30">
                    <div className="text-left m-r-30">
                        <div className="modal-title m-b-0">Echantillon</div>
                        <span className="lead">{dateFormat(ech?.date_prelevement,'DD/MM/YYYY')}</span>
                    </div>
                    <button className="btn btn-success" onClick={onExport}>Exporter</button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <section>
                    <DATALIST>
                        <DATALIST.ITEM title="Type de lait" value={type_lait} />
                        <DATALIST.ITEM title="Statut" value={statut_echantillon} />
                        <DATALIST.ITEM title="Litrage" value={litrage} />
                        {code_cc && <DATALIST.ITEM title="Commentaire" value={code_cc} />}
                    </DATALIST>
                </section>

                <section>
                    <h4 className="modal-subtitle">Résultats d’analyses</h4>
                    <TABLE variant="blue" responsive="accordion">
                        <TABLE.THEAD>
                            <TABLE.TR>
                                <TABLE.TH>Critère</TABLE.TH>
                                <TABLE.TH>Résultat</TABLE.TH>
                                <TABLE.TH>Unité</TABLE.TH>
                                <TABLE.TH>Note</TABLE.TH>
                                <TABLE.TH>Statut du résultat</TABLE.TH>
                                <TABLE.TH>Laboratoire sous-traitant</TABLE.TH>
                            </TABLE.TR>
                        </TABLE.THEAD>
                        <TABLE.TBODY>
                            {resultats && resultats.map((resultat: any) => (
                                <TABLE.TR key={resultat.id} title={resultat.cri_id.libelle}>
                                    <TABLE.TD hiddenMobile><strong>{resultat?.cri_id.libelle}</strong></TABLE.TD>
                                    <TABLE.TD label="Résultat" rightAlign>
                                        {['ANN', 'SUS'].includes(resultat?.str_id?.code) ? (<>
                                            {resultat?.str_id?.libelle}
                                        </>) : (<span className={classNames({'font-weight-bold': ['N', 'P'].includes(resultat.visibilite || ['N', 'P'].includes(echDetails.ech.visibilite))})}>
                                            {resultat?.apr_id?.libelle && <>
                                                {!resultat?.fl_pour_paiement ? (<span data-tip="Résultat hors paiement">({resultat?.apr_id?.libelle})</span>) : (<>{resultat?.apr_id?.libelle}</>)}
                                            </>}
                                            {exists(resultat?.valeur) && (<>
                                                {!resultat?.fl_pour_paiement ? (<span data-tip="Résultat hors paiement">({resultat?.signe}{numberFormat(resultat?.valeur, resultat?.cri_id?.nb_decimales)})</span>) : (<span>{resultat?.signe}{numberFormat(resultat?.valeur, resultat?.cri_id?.nb_decimales)}</span>)}
                                            </>)}
                                        </span>)}
                                    </TABLE.TD>
                                    <TABLE.TD label="Unité">{resultat?.uni_id?.lib_court || '-'}</TABLE.TD>
                                    <TABLE.TD label="Note">
                                        {['ANN', 'SUS'].includes(resultat?.str_id?.code) ? (<>-</>) : (<>{resultat?.note || '-'}</>)}
                                    </TABLE.TD>
                                    <TABLE.TD label="Statut du résultat">{resultat?.str_id.libelle || '-'}</TABLE.TD>
                                    <TABLE.TD label="Laboratoire sous-traitant">{formatSiretNomUsuel(resultat?.lab_id_sous_traitant?.ent_id?.siret, resultat?.lab_id_sous_traitant?.ent_id?.nom_usuel)}</TABLE.TD>
                                </TABLE.TR>
                            ))}
                        </TABLE.TBODY>
                    </TABLE>
                </section>

                <section>
                    <h4 className="modal-subtitle">Informations</h4>
                    <DATALIST>
                        <DATALIST.ITEM title="Signataire" value={signataire} />
                        <DATALIST.ITEM title="Laboratoire" value={formatSiretNomUsuel(ech?.lab_id?.ent_id?.siret, ech?.lab_id?.ent_id?.nom_usuel)} />
                        <DATALIST.ITEM title="Centre d'appartenance" value={formatSiretNomUsuel(ech?.ent_id_ca?.siret, ech?.ent_id_ca?.nom_usuel)} />
                        <DATALIST.ITEM title="Centre de collecte" value={formatSiretNomUsuel(ech?.ent_id_cc?.siret, ech?.ent_id_cc?.nom_usuel)}/>
                        {ech?.tye_id?.code === 'CIT' ?
                            <DATALIST.ITEM title="Usine de dépotage" value={formatSiretNomUsuel(ech?.ent_id_ud?.siret, ech?.ent_id_ud?.nom_usuel)} />
                            : <DATALIST.ITEM title="Producteur" value={formatSiretNomUsuel(ech?.prd_id?.ent_id.siret, ech?.prd_id?.ent_id?.nom_usuel)} />
                        }
                    </DATALIST>
                </section>
            </Modal.Body>
            <Modal.Footer />
        </Modal>
    )
}