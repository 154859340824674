import { withRouter } from "react-router-dom";
import CustomComponent from "../../components/CustomComponent";
import { hasPermission } from "../../helpers/helpers";
import { Forbidden } from "../_error_pages";

const REDIRECTIONS = [
    { permissions: ['DRT_AIDE_CNIEL'], path: '/aide/cniel' },
    { permissions: ['DRT_AIDE_LABORATOIRE'], path: '/aide/laboratoire'},
    { permissions: ['DRT_AIDE_LAITERIE'], path: '/aide/laiterie'},
    { permissions: ['DRT_AIDE_PRODUCTEUR'], path: '/aide/producteur' },
]

class AccueilAide extends CustomComponent {
    componentDidMount() {
        for (let redirection of REDIRECTIONS) {
            if (hasPermission(redirection.permissions)) return this.props.history.push(redirection.path)
        }
    }

    render () {
        return <Forbidden />
    }
}

export default withRouter(AccueilAide);