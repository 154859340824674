export enum TYPES_ROUTAGE {
    LAI = 'LAI',
    GRP = 'GRP',
}

export const TYPES_ROUTAGE_LABEL: {[key: string]: string} = {
    [TYPES_ROUTAGE.LAI]: 'Laiterie',
    [TYPES_ROUTAGE.GRP]: 'Groupe',
};

export const TYPES_ROUTAGE_OPTIONS = Object.entries(TYPES_ROUTAGE_LABEL).map(([value, label]) => ({
    value, label
}))