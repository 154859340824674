import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import BreadCrumb from "../../../components/BreadCrumb";
import CustomComponent from "../../../components/CustomComponent";
import { DebugDropdown } from "../../../components/DebugDropdown";
import { DefaultLayout } from "../../../components/Layouts";
import { ENABLE_DEBUG_ROUTE } from "../../../config";
import { TOAST_TYPE, hasPermission, sendToast } from "../../../helpers/helpers";

const links = [
    { permissions: ['DRT_FLUX_RESULTATS'], label: "Imports et exports de résultats", to: "/flux/imports_exports_resultats" },
    { permissions: ['DRT_FLUX_EXPORT_LAITERIE'], label: "Paramétrage des exports laiterie", to: "/flux/exports_laiterie" },
    { permissions: ['DRT_FLUX_PUSH'], label: "Suivi des envois", to: "/flux/suivi_des_envois" },
    { permissions: ['DRT_FLUX_ABONNEMENT'], label: "Abonnements", to: "/flux/abonnements" },
    { permissions: ['DRT_FLUX_ANNUAIRE'], label: "Imports et exports annuaire", to: "/flux/imports_exports_annuaire/imports" },
    { permissions: ['DRT_FLUX_REFERENCES'], label: "Tables de références", to: "/flux/tables_de_references" },
]

class AccueilFlux extends CustomComponent {
    componentDidMount() {
        document.title = 'Infolabo | Flux';
    }

    handleDebugButton = (e: any) => {
        e.preventDefault();
        this.request(`/debug/${e.target.value}`, 'POST')
            .then((data) => {
                data?.statusCode === 200 && sendToast(data?.message, TOAST_TYPE.SUCCESS);
            })
    }

    render() {
        if (this.props.noLayout) return this.renderContent()

        return (
            <DefaultLayout>
                <div className="container">
                    <BreadCrumb crumbs={[{ name: 'Flux', path: '/flux' }]} />
                    {this.renderContent()}
                </div>
            </DefaultLayout>
        )
    }

    renderContent() {
        return (
            <>
                <div className="d-flex justify-content-between align-items-start flex-wrap m-b-15">
                    <h1 className="main-title m-b-15">Flux</h1>
                    <div className='d-flex flex-wrap m-b-5'>
                        {ENABLE_DEBUG_ROUTE && <DebugDropdown />}
                    </div>
                </div>
                <div className="row">
                    {links.filter(link => hasPermission(link.permissions)).map((link, index) => (
                        <div key={index} className="col-sm-6 col-md-4 col-lg-3">
                            <Link to={link.to} className="card-link">{link.label}</Link>
                        </div>
                    ))}
                </div>
            </>
        )
    }
}

export default withTranslation()(withRouter(AccueilFlux));

