import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb';
import CustomComponent from '../../components/CustomComponent';
import { DefaultLayout } from '../../components/Layouts';
import { INotification, Notification } from '../../components/Notification';
import {ResultatsCiterneList, ResultatsProductionDetails, ResultatsProductionList} from '../resultats';
import {AccueilFlux} from "../flux";

class Home extends CustomComponent {
    constructor(props: any) {
        super(props);

        this.state = {
            notifications: [],
            ent_id: null,
            dashboard: null
        }
    }

    componentDidMount() {
        if (this.checkIsConnectedPWA()) {
            this.loadDashBoard()
            this.getNotifications()
        }
    }

    async loadDashBoard () {
        const session = this.getLocalStorageSession() as any
        const habilitation = this.getHabilitation()
        const ent_id = habilitation?.ent_id.id
        const prd_id = session?.prd_id
        const code_hab = habilitation?.hab_code?.code_hab
        const code_classe = (await this.request(`/ref_classe_entite/${habilitation?.ent_id.cla_id}`, 'GET')).code_classe

        this.setState({
            ent_id,
            prd_id,
            code_hab,
            code_classe,
        })
    }

    getNotifications () {
        // TODO get real data
        const data = {
            notifications: [
                // {
                //     variant: 'success',
                //     title: "NOUVELLE ANNONCE - ASTUCE N°05 – Modifier son identifiant ou son mot de passe",
                //     text: "Morbi egestas nibh eget lorem finibus, eget tincidunt sem condimentum. Maecenas massa nisi, mollis sed egestas at, lacinia quis eros. Nullam ac sem id est dictum tincidunt. Integer euismod mauris ipsum, nec mattis mauris gravida faucibus. Pellentesque metus nisl.",
                //     link: 'https://www.google.com'
                // },
            ]
        }

        if (data && data.notifications) {
            this.setState({notifications: data.notifications})
        }
    }

    renderContent() {
        switch (this.state.code_classe) {
            case 'LAB':
                if (this.state.code_hab === 'ADMINLAB') return <AccueilFlux noLayout/>;
                if (['GESTICON', 'EXTRAENT'].includes(this.state.code_hab)) return <ResultatsProductionList noLayout/>;
                break;
            case 'CNI':
                if (this.state.code_hab === 'ADMININF') return <AccueilFlux noLayout/>;
                if (this.state.code_hab === 'EXTRAENT') return <ResultatsProductionList noLayout/>;
                break;
            case 'PRO':
                return <ResultatsProductionDetails noLayout displayParameters ent_id={this.state.prd_id}/>;
            case 'LAI':
                if (['CONSECHA', 'CONSECIT'].includes(this.state.code_hab)) return <ResultatsCiterneList noLayout/>;
                return <ResultatsProductionList noLayout/>;
            case 'OIP':
            case 'ORG':
                return <ResultatsProductionList noLayout/>;
        }
    }

    render() {
        return (
            <DefaultLayout displayAnnonceBanner>
                <div className="container">
                    <BreadCrumb />
                    {this.state.notifications.length > 0 && this.state.notifications.map((notification: INotification) => <Notification notification={notification} />)}
                    {this.renderContent()}
                </div>
            </DefaultLayout>
        )
    }
}

export default withTranslation()(withRouter(Home));
