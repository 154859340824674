import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import BreadCrumb from '../../../components/BreadCrumb';
import CustomComponent from '../../../components/CustomComponent';
import { FormInput, FormInputSelect, FormMultiCheckBox, FormRadioValues } from '../../../components/FormComponents';
import { DefaultLayout } from '../../../components/Layouts';
import Panel from '../../../components/Panel';
import { FORMAT_FICHIER, FORMAT_FICHIER_OPTIONS } from '../../../enum/format_fichier';
import { MEDIA, MEDIA_OPTIONS } from '../../../enum/media';
import { TYPES_ROUTAGE, TYPES_ROUTAGE_OPTIONS } from '../../../enum/type_routage';
import { TYPES_EXPORT, TYPES_EXPORT_OPTIONS } from '../../../enum/types_export';
import { TYPES_LAITERIE_OPTIONS } from '../../../enum/types_laiterie';
import { TYPES_RESULTATS, TYPES_RESULTATS_OPTIONS } from '../../../enum/types_resultats';
import { TOAST_TYPE, hasPermission, sendToast } from '../../../helpers/helpers';
import { initValidator } from '../../../validations/validations';

const export_laiterie_default_values = {
    type_routage: TYPES_ROUTAGE.LAI,
    ent_id_lai: '',
    media: MEDIA.MAIL,
    type_laiterie: [],
    grp_id: '',
    type_resultat: '',
    type_export: '',
    format_fichier: FORMAT_FICHIER.CSV,
}

class ExportLaiterieCreate extends CustomComponent {
    private _isMounted = false;
    private validator

    constructor(props: any) {
        super(props);

        let page_title = "Création d'un export laiterie"

        this.state = {
            page_title: page_title,
            destinataire: {
                ent_id_destinataire: '',
                mailing_list: [''],
                email_destinataire: '',
            },
            exports_laiterie: [
                {
                    ...export_laiterie_default_values,
                    validator: initValidator()
                }
            ],
            options: {
                ent_id_destinataire: [],
                ent_id_lai_default: [],
                ent_id_lai: Array(5).fill([]),
                grp_id: Array(5).fill([]),
                type_routage: TYPES_ROUTAGE_OPTIONS,
                type_laiterie: TYPES_LAITERIE_OPTIONS,
                type_resultat: TYPES_RESULTATS_OPTIONS,
                type_export: TYPES_EXPORT_OPTIONS,
                format_fichier: FORMAT_FICHIER_OPTIONS,
                media: MEDIA_OPTIONS,
            },
            has_email_source: true,
            display_email_destinataire_2: false,
            isLoading: false,
        }
        this.validator = initValidator()
    }

    componentDidMount() {
        this._isMounted = true;
        this.getEntiteOptions(null, 'DESTINATAIRE')
        this.getEntiteOptions(null, 'LAITERIE')
        this.getGroupeOptions(null)
        document.title = `Infolabo | ${this.state.page_title}`;
    }

    getEntiteOptions = async (e: any | null = null, type_entite: 'DESTINATAIRE' | 'LAITERIE', index?: number) => {
        const ROUTE = {
            'DESTINATAIRE': 'destinataire',
            'LAITERIE': 'laiterie'
        }

        let URL: string = `/exp_export_laiterie/autocomplete/${ROUTE[type_entite]}`

        if (e) {
            if (e.action.action !== 'input-change') return
            URL += `?query=${e.data}`
        }

        const data = await this.request(URL, 'GET')

        if (data && data.length) {
            const new_options = data.map((option: any) => ({ label: option.label, value: option.id, types_entites: option.types_entites, mail_entite: option.mail_entite }))

            switch (type_entite) {
                case 'DESTINATAIRE':
                    this._isMounted && this.setState((prev: any) => ({
                        options: { ...prev.options, ent_id_destinataire: new_options }
                    }));
                    break;
                case 'LAITERIE':
                    this._isMounted && this.setState((prev: any) => ({
                        options: {
                            ...prev.options,
                            ent_id_lai: prev.options.ent_id_lai.map((ent_id_lai: any, i: number) => {
                                if (index !== i && ent_id_lai.length > 0) return ent_id_lai
                                return new_options
                            })
                        }
                    }));
                    break;
                default:
                    break;
            }
        }
    }

    getGroupeOptions = async (e: any, index?: number) => {
        let URL: string = `/ent_groupe/autocomplete`
        const params = new URLSearchParams()
        params.append('type_groupe', 'E')

        if (e) {
            if (e.action.action !== 'input-change') return
            params.append('query', e.data)
        }

        const data = await this.request(`${URL}?${params.toString()}`, 'GET')

        if (data && data.statusCode === 200) {
            const new_options = data.groupes.map((option: any) => ({ label: option.nom, value: option.id }))

            this._isMounted && this.setState((prev: any) => ({
                options: {
                    ...prev.options,
                    grp_id: prev.options.grp_id.map((grp_id: any, i: number) => {
                        if (index !== i && grp_id.length > 0) return grp_id
                        return new_options
                    })
                }
            }))
        }
    }

    afterSubmission = async (e: any) => {
        e.preventDefault();
        const { t } = this.props;

        this._isMounted && this.setState({ isLoading: true, isSubmitted: true });

        if (this.allValidatorsValid()) {
            await this.createExportsLaiteries();
        } else {
            this.showAllValidorMessages();
            sendToast(<p>{t('error_bad_field_value')}</p>, TOAST_TYPE.ERROR);
        }

        this._isMounted && this.setState({ isLoading: false });
    }

    allValidatorsValid = () => {
        const validators = [...this.state.exports_laiterie.map((export_laiterie: any) => export_laiterie.validator), this.validator];
        for (let validator of validators) {
            if (!validator.allValid()) return false;
        }
        return true;
    }

    showAllValidorMessages = () => {
        const validators = [...this.state.exports_laiterie.map((export_laiterie: any) => export_laiterie.validator), this.validator];
        for (let validator of validators) {
            validator.showMessages();
        }
    }

    createExportsLaiteries = async () => {
        const URL = `/exp_export_laiterie`;

        const BODY = {
            exports_laiterie: this.state.exports_laiterie.map((export_laiterie: any) => ({
                ...export_laiterie,
                ent_id_destinataire: this.state.destinataire.ent_id_destinataire,
                mailing_list: this.state.has_email_source ? JSON.stringify(this.state.destinataire.mailing_list) : "",
                fl_lai_typ_cc: export_laiterie.type_laiterie.includes('CC'),
                fl_lai_typ_ca: export_laiterie.type_laiterie.includes('CA'),
                fl_lai_typ_ud: export_laiterie.type_laiterie.includes('UD'),
                validator: undefined
            }))
        };

        this.setState({ isLoading: true });
        const data = await this.request(URL, 'POST', BODY);
        this.setState({ isLoading: false });

        if (data && data.statusCode === 201) {
            sendToast(data.message, TOAST_TYPE.SUCCESS);
            this.props.history.push(`/flux/exports_laiterie`);
        }
    }

    addExportLaiterie = (e: any) => {
        e.preventDefault()
        this._isMounted && this.setState((prev: any) => ({
            exports_laiterie: [...prev.exports_laiterie, {
                ...export_laiterie_default_values,
                validator: initValidator()
            }]
        }), () => {
            this.updateHasEmailSource()
        })
    }

    removeExportLaiterie = (index: number) => {
        this._isMounted && this.setState((prev: any) => ({
            exports_laiterie: prev.exports_laiterie.filter((_: any, i: number) => index !== i)
        }), () => {
            this.updateHasEmailSource()
        })
    }

    handleDonneesInputChange = (e: any) => {
        const { target, action, data } = e
        let [name, value]: any = ['', '']

        if (target) [name, value] = [target.name, target.value];
        if (action && data) [name, value] = [action.name, data.value]

        const [key, index] = name?.split('--')

        if (key === 'ent_id_lai') {
            this.setState((prev: any) => ({
                exports_laiterie: prev.exports_laiterie.map((entry: any, i: number) => {
                    if (i === +index) return {
                        ...entry,
                        type_laiterie: []
                    }
                    return entry
                })
            }))
        }

        if (key === 'type_laiterie') {
            const values = this.state.exports_laiterie[index].type_laiterie
            value = values.includes(value)
                ? [...values.filter((val: string) => val !== value)]
                : [...values, value]
        }

        if (key === 'type_routage') {
            this.state.exports_laiterie[index].validator.purgeFields()
        }

        this.setState((prev: any) => ({
            exports_laiterie: prev.exports_laiterie.map((entry: any, i: number) => {
                if (i === +index) return {
                    ...entry,
                    [key]: value
                }
                return entry
            })
        }), this.updateHasEmailSource)
    }

    handleMailingListChange(e: any) {
        const value = e.target.value
        const index = e.target.name.split('--').pop()

        this.setState((prev: any) => ({
            destinataire: {
                ...prev.destinataire,
                mailing_list: prev.destinataire.mailing_list.map((email: string, i: number) => {
                    if (i === +index) return value;
                    return email
                })
            }
        }))
    }

    addEmailToMailingList = () => {
        this.setState((prev: any) => ({
            destinataire: {
                ...prev.destinataire,
                mailing_list: [...prev.destinataire.mailing_list, '']
            }
        }))
    }

    removeEmailFromMailingList = (index: number) => {
        this.setState((prev: any) => ({
            destinataire: {
                ...prev.destinataire,
                mailing_list: prev.destinataire.mailing_list.length === 1
                    ? ['']
                    : prev.destinataire.mailing_list.filter((_: any, i: number) => i !== +index)
            }
        }))
    }

    updateHasEmailSource = () => {
        this.setState({
            has_email_source: this.state.exports_laiterie.filter((entry: any) => entry.media === MEDIA.MAIL).length > 0
        })
    }

    getFilteredOptionsLaiterie = (index: number) => {
        const ent_id_lai = this.state.exports_laiterie[index].ent_id_lai
        const selected = this.state.options.ent_id_lai[index].find((option: any) => option.value === ent_id_lai)

        return this.state.options.type_laiterie.map((option: any) => {
            let disabled = !selected
            if (option.value === "CA" && !selected?.types_entites.includes('CEAPP')) disabled = true
            if (option.value === "CC" && !selected?.types_entites.includes('CECOL')) disabled = true
            if (option.value === "UD" && !selected?.types_entites.includes('CEDEP')) disabled = true
            return { ...option, disabled }
        })
    }

    getFilteredOptionsTypeResultats = (index: number) => {
        const type_routage = this.state.exports_laiterie[index].type_routage
        const selected = this.state.exports_laiterie[index].type_resultat

        if (type_routage === TYPES_ROUTAGE.GRP && selected === TYPES_RESULTATS.RJC) {
            this._isMounted && this.setState((prev: any) => ({
                exports_laiterie: prev.exports_laiterie.map((export_laiterie: any, i: number) => {
                    if (index === i) {
                        return {
                            ...export_laiterie,
                            type_resultat: ''
                        }
                    }

                    return export_laiterie
                })
            }))
        }

        return this.state.options.type_resultat.filter((option: any) => {
            return !(type_routage === TYPES_ROUTAGE.GRP && option.value === TYPES_RESULTATS.RJC)
        })
    }

    getFilteredOptionsTypeExport = (index: number) => {
        const type_resultat = this.state.exports_laiterie[index].type_resultat
        const selected = this.state.exports_laiterie[index].type_export

        if (type_resultat === TYPES_RESULTATS.RM && [TYPES_EXPORT.CUMUL_FINJOUR, TYPES_EXPORT.CUMUL_IMMEDIAT].includes(selected)) {
            this._isMounted && this.setState((prev: any) => ({
                exports_laiterie: prev.exports_laiterie.map((export_laiterie: any, i: number) => {
                    if (index === i) {
                        return {
                            ...export_laiterie,
                            type_export: ''
                        }
                    }

                    return export_laiterie
                })
            }))
        }

        return this.state.options.type_export.filter((option: any) => {
            return !(type_resultat === TYPES_RESULTATS.RM && [TYPES_EXPORT.CUMUL_FINJOUR, TYPES_EXPORT.CUMUL_IMMEDIAT].includes(option.value))
        })
    }

    setEntiteDestinataireEmail = () => {
        const { ent_id_destinataire, mailing_list, email_destinataire } = this.state.destinataire
        const firstMailOfMailingList = mailing_list[0]
        const entite_destinataire = this.state.options.ent_id_destinataire.find((option: any) => option.value === ent_id_destinataire)
        const mail_entite = entite_destinataire?.mail_entite

        if (firstMailOfMailingList === email_destinataire || firstMailOfMailingList === '') mailing_list.shift()
        if (mail_entite) mailing_list.unshift(mail_entite)
        if (mailing_list.length === 0) mailing_list.push('')

        this.setState((prev: any) => ({
            destinataire: {
                ...prev.destinataire,
                mailing_list,
                email_destinataire: mail_entite
            }
        }))
    }

    render() {
        return (
            <DefaultLayout loading={this.state.isLoading}>
                <div className="container">
                    <BreadCrumb crumbs={[{ name: 'Flux', path: '/flux' }, { name: 'Export laiterie', path: '/flux/exports_laiterie' }, { name: this.state.page_title }]} />
                    <form onSubmit={(e) => this.afterSubmission(e)}>
                        <div className="d-flex justify-content-between flex-wrap">
                            <h1 className="main-title m-r-50">{this.state.page_title}</h1>
                            <div className="m-b-30">
                                {hasPermission('DRT_FLUX_EXPORT_LAITERIE') && <Link to={`/flux/exports_laiterie`} className="btn m-r-5">Annuler</Link>}
                                {hasPermission('DRT_FLUX_EXPORT_LAITERIE_GERER') && <button type="submit" className='btn btn-secondary'>Enregistrer</button>}
                            </div>
                        </div>
                        <Panel title="Destinataire" togglableID='panel-1' open>
                            <div className="large-gutters">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInputSelect
                                                label="Nom de l'entité / SIRET"
                                                name="ent_id_destinataire"
                                                id="ent_id_destinataire"
                                                simpleValidator={this.validator}
                                                required
                                                isClearable
                                                value={this.state.destinataire.ent_id_destinataire}
                                                handleInput={(e: any) => this.getEntiteOptions(e, 'DESTINATAIRE')}
                                                handle={(e: any) => this.handleInputChange(e, null, 'destinataire', this.setEntiteDestinataireEmail)}
                                                options={this.state.options.ent_id_destinataire} />
                                        </div>
                                    </div>
                                    <div className="col">
                                        {this.state.destinataire.mailing_list.map((email: string, index: number) => (
                                            <div className={`form-group form-group--inline ${this.state.display_email_destinataire_2 ? 'm-b-30' : ''}`}>
                                                <FormInput
                                                    label={`Email ${index + 1}`}
                                                    name={`mailing_list--${index}`}
                                                    id={`mailing_list--${index}`}
                                                    value={this.state.has_email_source ? this.state.destinataire.mailing_list[index] : ''}
                                                    handle={(e: any) => this.handleMailingListChange(e)}
                                                    maxLength={70}
                                                    validator_type="email"
                                                    simpleValidator={this.validator}
                                                    required={this.state.has_email_source && index === 0}
                                                    onClear={() => this.removeEmailFromMailingList(index)}
                                                    disabled={!this.state.has_email_source}>
                                                    {!!email && this.state.has_email_source && index === this.state.destinataire.mailing_list.length - 1 && (
                                                        <button type="button" onClick={this.addEmailToMailingList} className="action-btn action-btn--light action-btn--large m-l-10">
                                                            <i className="icon-add"></i>
                                                        </button>
                                                    )}
                                                </FormInput>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel title="Données à transmettre" togglableID='panel-2' open>
                            {this.state.exports_laiterie.map((export_laiterie: any, index: number) => (
                                <div key={index} className="panel__section">
                                    {this.state.exports_laiterie.length > 1 && (
                                        <button className='action-btn ml-auto' type="button" onClick={() => this.removeExportLaiterie(index)}>
                                            <span className="sr-only">Supprimer</span><i className="icon-trash"></i>
                                        </button>
                                    )}
                                    <div className="large-gutters row">
                                        <div className="col-12">
                                            <div className="form-group form-group--inline form-group--radio m-b-30">
                                                <FormRadioValues
                                                    required
                                                    label="Source des données"
                                                    id={`type_routage--${index}`}
                                                    name={`type_routage--${index}`}
                                                    value={export_laiterie.type_routage}
                                                    options={this.state.options.type_routage}
                                                    handle={this.handleDonneesInputChange}
                                                    isSubmitted={this.state.isSubmitted} />
                                            </div>
                                            {export_laiterie.type_routage === TYPES_ROUTAGE.LAI && (<>
                                                <div className="form-group form-group--inline m-b-30">
                                                    <FormInputSelect
                                                        label={`Nom de l'entité / SIRET`}
                                                        name={`ent_id_lai--${index}`}
                                                        id={`ent_id_lai--${index}`}
                                                        required={export_laiterie.type_routage === TYPES_ROUTAGE.LAI}
                                                        isClearable
                                                        simpleValidator={export_laiterie.validator}
                                                        value={export_laiterie.ent_id_lai}
                                                        handleInput={(e: any) => this.getEntiteOptions(e, 'LAITERIE', index)}
                                                        handle={this.handleDonneesInputChange}
                                                        options={this.state.options.ent_id_lai[index]} />
                                                </div>
                                                <div className="form-group form-group--inline form-group--radio m-b-30">
                                                    <FormMultiCheckBox
                                                        label="Type de la laiterie"
                                                        name={`type_laiterie--${index}`}
                                                        id={`type_laiterie--${index}`}
                                                        handle={this.handleDonneesInputChange}
                                                        required={export_laiterie.type_routage === TYPES_ROUTAGE.LAI && !!export_laiterie.ent_id_lai}
                                                        simpleValidator={export_laiterie.validator}
                                                        values={export_laiterie.type_laiterie}
                                                        isSubmitted={this.state.isSubmitted}
                                                        options={this.getFilteredOptionsLaiterie(index)} />
                                                </div>
                                            </>)}
                                            {export_laiterie.type_routage === TYPES_ROUTAGE.GRP && (
                                                <div className="form-group form-group--inline m-b-30">
                                                    <FormInputSelect
                                                        label="Groupe"
                                                        name={`grp_id--${index}`}
                                                        id={`grp_id--${index}`}
                                                        required={export_laiterie.type_routage === TYPES_ROUTAGE.GRP}
                                                        isClearable
                                                        simpleValidator={export_laiterie.validator}
                                                        value={export_laiterie.grp_id}
                                                        handleInput={(e: any) => this.getGroupeOptions(e, index)}
                                                        handle={this.handleDonneesInputChange}
                                                        options={this.state.options.grp_id[index]} />
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group form-group--inline m-b-30">
                                                <FormInputSelect
                                                    label="Type de résultats"
                                                    name={`type_resultat--${index}`}
                                                    id={`type_resultat--${index}`}
                                                    required
                                                    simpleValidator={export_laiterie.validator}
                                                    isClearable
                                                    value={export_laiterie.type_resultat}
                                                    handle={this.handleDonneesInputChange}
                                                    options={this.getFilteredOptionsTypeResultats(index)} />
                                            </div>
                                            <div className="form-group form-group--inline m-b-30">
                                                <FormInputSelect
                                                    label="Type d'export"
                                                    name={`type_export--${index}`}
                                                    id={`type_export--${index}`}
                                                    required
                                                    simpleValidator={export_laiterie.validator}
                                                    isClearable
                                                    value={export_laiterie.type_export}
                                                    handle={this.handleDonneesInputChange}
                                                    options={this.getFilteredOptionsTypeExport(index)} />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group form-group--inline form-group--radio m-b-30">
                                                <FormRadioValues
                                                    required
                                                    label="Média"
                                                    id={`media--${index}`}
                                                    name={`media--${index}`}
                                                    value={export_laiterie.media}
                                                    options={this.state.options.media}
                                                    handle={this.handleDonneesInputChange}
                                                    isSubmitted={this.state.isSubmitted} />
                                            </div>
                                            <div className="form-group form-group--inline form-group--radio m-b-30">
                                                <FormRadioValues
                                                    required
                                                    label="Format fichier"
                                                    id={`format_fichier--${index}`}
                                                    name={`format_fichier--${index}`}
                                                    value={export_laiterie.format_fichier}
                                                    options={this.state.options.format_fichier}
                                                    handle={this.handleDonneesInputChange}
                                                    isSubmitted={this.state.isSubmitted} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {this.state.exports_laiterie.length < 5 && (
                                <button
                                    onClick={this.addExportLaiterie}
                                    type="button"
                                    className='info-card info-card--button m-t-10'>
                                    Ajouter<i className="icon-add-circle"></i>
                                </button>
                            )}
                        </Panel>
                    </form>
                </div>
            </DefaultLayout>
        )
    }
}

export default withTranslation()(withRouter(ExportLaiterieCreate));
