import { withRouter } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb';
import CustomComponent from '../../components/CustomComponent';
import { LoginLayout } from '../../components/Layouts';


class DonnesPersonnelles extends CustomComponent {
    componentDidMount() {
        document.title = 'Infolabo';
        //        this.checkIsConnectedPWA();
    }

    render() {
        return (
            <LoginLayout>
                <div className="container page-login__wrapper">
                    <BreadCrumb crumbs={[{ name: 'Données personnelles', path: '/donnees_personnelles' }]} />
                    <h1 className="main-title text-center">Données personnelles</h1>
                    <div className="page-login__legal-notice">
                        <p>La présente politique de gestion des Données personnelles est conçue pour vous informer sur la façon dont les données personnelles sont utilisées et, également, vous rappeler quels sont vos droits et comment vous pouvez les exercer. </p>
                        <section>
                            <h3>Qui est responsable de mes données personnelles ?</h3>
                            <p>Infolabo® est  un  système  informatique  de  l’interprofession  laitière,  conçu  conjointement  par  le  Centre  National Interprofessionnel de l'Économie Laitière (CNIEL) et les laboratoires habilités pour les analyses de paiements du lait.</p>
                            <p>Infolabo® est dédié aux services en ligne de diffusion, de consultation et de restitution des résultats d’analyses de lait aux producteurs, entreprises laitières et autres entités habilitées et concernées par le traitement de ces données.</p>
                            <p>Le  CNIEL  est  l’administrateur  central  du  système  Infolabo® et  le  point  de  contact  unique  pour  toute  demande  des utilisateurs :</p>
                            <address>CNIEL  -  Association  loi  1901  déclarée  à  la  Préfecture  de  Paris  le  8  avril  1974,  enregistrée  sous  le n°W751035614, dont le siège social est à la Maison du Lait, 42 rue de Châteaudun, 75314 Paris cedex 09. Tel. : <a href="tel:+33149707111">+ 33 1 49 70 71 11</a> – <a href='https://www.filiere-laitiere.fr'>https://www.filiere-laitiere.fr</a>.</address>
                            <p>
                                La  liste  des  laboratoires  interprofessionnels  habilités  est  disponible  à  l’adresse  suivante : <br />
                                <a href='https://www.filiere-laitiere.fr/sites/default/files/medias/documents/liste_des_laboratoires_habilites_pour_les_analyses_de_paiements_du_lait_0.pdf'>https://www.filiere-laitiere.fr/sites/default/files/medias/documents/liste_des_laboratoires_habilites_pour_les_analyses_de_paiements_du_lait_0.pdf</a>
                            </p>
                        </section>
                        <section>
                            <h3>Quelles données personnelles sont enregistrées ?</h3>
                            <ul>
                                <li>
                                    <h4>Données personnelles fournies par l’utilisateur</h4>
                                    <p>Le  système  Infolabo® est  réservé  aux  utilisateurs  qui  ont  un  compte  en  ligne.  La  création  d’un  compte  s’effectue  au moyen du formulaire en ligne prévu à cet effet. Les données personnelles qui sont collectées dans ce formulaire sont enregistrées par le système. Le caractère obligatoire ou facultatif des données est signalé au moment de leur collecte par un astérisque.</p>
                                    <p>Les informations suivantes doivent être renseignées :</p>
                                    <ul>
                                        <li>Nom ;</li>
                                        <li>Prénom ;</li>
                                        <li>Adresse email ;</li>
                                        <li>Nom usuel de l’entité ;</li>
                                        <li>Numéro de SIRET</li>
                                    </ul>
                                    <p>L’utilisateur peut également indiquer, à titre facultatif, les informations suivantes :</p>
                                    <ul>
                                        <li>Numéro de téléphone.</li>
                                    </ul>
                                </li>
                                <li>
                                    <h4>Données personnelles transmises par les laboratoires interprofessionnels</h4>
                                    <p>Les  laboratoires  interprofessionnels  peuvent  transmettre  directement  dans  le  système  Infolabo® les  données personnelles des producteurs déjà enregistrées dans leur système, et sont informés par l’administrateur du CNIEL de toute  demande  de  création  de  compte  et  de  mises  à  jour  des  données,  afin  de  s’assurer  de  la  cohérence  des informations pour identifier correctement les personnes concernées et faire un lien avec leurs résultats d’analyses de lait.</p>
                                </li>
                                <li>
                                    <h4>Données personnelles issues des laiteries</h4>
                                    <p>A  la  demande  d’une  laiterie,  l’administrateur  du  CNIEL  peut  créer  un  compte  « administrateur  laiterie »  pour  chaque entité. Les administrateurs laiteries ont ensuite la possibilité de créer des comptes pour leurs collaborateurs autorisés à accéder aux résultats d’analyse.</p>
                                </li>
                                <li>
                                    <h4>Données personnelles collectées automatiquement</h4>
                                    <p>Lorsque  l’utilisateur  se  connecte  à  son  compte  personnel  pour  accéder  à  ses  données  sur  le  système  Infolabo® au moyen  d’un  ordinateur  fixe  ou  portable,  d’un  mobile  multifonction  ou  d’une  tablette,  des  informations  techniques  de connexion et de navigation sont enregistrées par le système.</p>
                                    <p>Des  cookies  sont  également  déposés  sur  l’équipement  terminal  de  l’utilisateur  lorsqu’il  se  connecte  au  système Infolabo®. Pour connaître les différents cookies utilisés et les moyens de les paramétrer, voir <a href="#cookies">ci-dessous</a>.</p>
                                </li>
                            </ul>
                        </section>
                        <section>
                            <h3>Pour quelles utilisations les données personnelles sont-elles enregistrées ?</h3>
                            <ul>
                                <li>
                                    <h4>Finalité principale</h4>
                                    <p>L’objectif principal du traitement des données personnelles est de pouvoir authentifier et identifier les utilisateurs pour l’accès, la restitution et la diffusion de leurs résultats d’analyses de lait, réalisés par les laboratoires interprofessionnels, dans le cadre du paiement du lait.</p>
                                    <p>Ce traitement de données est mis en œuvre en application des bases légales suivantes :</p>
                                    <ul>
                                        <li>Article 6-c du règlement général sur la protection des données (RGPD) ;</li>
                                        <li>Accord interprofessionnel national en vigueur relatif au paiement du lait de vache en fonction de sa composition et de sa qualité ;</li>
                                        <li>Accord interprofessionnel national en vigueur relatif à l’application de la réglementation pour les germes et les cellules somatiques lors de la collecte du lait de vache à l’exploitation agricole ;</li>
                                        <li>Arrêtés ministériels en vigueur d’extension des accords interprofessionnels.</li>
                                    </ul>
                                    <p>Les données personnelles sont utilisées pour fournir les services suivants :</p>
                                    <ul>
                                        <li>Enregistrement des résultats d’analyses de lait réalisés par les laboratoires interprofessionnels ;</li>
                                        <li>Mise à disposition des résultats d’analyses de lait aux producteurs, entreprises privées, coopératives laitières et autres entités concernées et habilitées ;</li>
                                        <li>Notifications des résultats d’analyses de lait par push, email ou SMS ;</li>
                                        <li>Restitution des résultats d’analyses de lait sous forme de fichiers à télécharger ;</li>
                                        <li>Etablissement  des  factures  de  paiement  du  lait  aux  producteurs à  partir  des  données  Infolabo® par  les entreprises privées, coopératives laitières ou toutes autres entités habilitées ;</li>
                                        <li>Réalisation de statistiques sur l’utilisation du système Infolabo® ;</li>
                                        <li>Réalisation  de  statistiques  sur  la  composition  ou  la  qualité  du  lait :  Les  états  statistiques  ainsi  réalisés  ne présentent que des données agrégées et consolidées ;</li>
                                        <li>Réalisation  de  recherches  sur  la  composition  ou  la  qualité  du  lait :  les  résultats  de  recherches  publiés  ne présentent que des données agrégées et consolidées ;</li>
                                        <li>Gestion des procédures hors normes en germes ou en cellules (outil Germes & Cellules du CNIEL).</li>
                                    </ul>
                                </li>
                                <li>
                                    <h4>Finalités complémentaires</h4>
                                    <p>Les données personnelles des utilisateurs sont également utilisées sur la base des textes suivants :</p>
                                    <ul>
                                        <li>Article 6-f du règlement général sur la protection des données (RGPD) ;</li>
                                        <li>Prévention, détection et lutte contre la fraude informatique pour la sécurité et la confidentialité des données et du système Infolabo®;</li>
                                        <li>A des fins de preuve dans le cadre d’obligations légales et réglementaires ;</li>
                                        <li>Pour instruire les demandes liées à l’exercice des droits des utilisateurs concernant leurs données personnelles.</li>
                                    </ul>
                                </li>
                            </ul>
                        </section>
                        <section>
                            <h3>Qui peut accéder aux données personnelles ?</h3>
                            <ul>
                                <li>
                                    <h4>Les destinataires habilités à accéder aux données</h4>
                                    <p>Les données personnelles enregistrées dans le système Infolabo® sont accessibles aux organismes habilités suivants :</p>
                                    <ul>
                                        <li>Le  service  interne  du  CNIEL  pour  l’administration  centrale  du  système,  ainsi  que  le  service  chargé  des statistiques et des procédures hors normes en germes ou en cellules (Accord interprofessionnel national du 6 juillet 2016) ;</li>
                                        <li>Les  entreprises  privées,  coopératives  laitières  ou  toutes  autres  entités  habilitées  pour  établir  les  factures  de lait ;</li>
                                        <li>Les  services  internes  des  laboratoires  interprofessionnels  et  des  entreprises  laitières  pour  le  traitement  des résultats d’analyses de lait ;</li>
                                        <li>Les  centres  d’appartenance  et  de  collecte  ainsi  que,  le  cas  échéant,  d’autres  entités  habilitées  avec  le consentement des copropriétaires des données dans le cadre du ramassage pour compte ; </li>
                                        <li>La  Direction  Générale  de  l’Alimentation  (DGAL)  du  ministère  de  l’Agriculture  pour  les  besoins  de  la  base  de données nationale du domaine vétérinaire (SIGAL).</li>
                                    </ul>
                                    <p>Les données personnelles sont également susceptibles d’être transmises sur réquisitions des autorités administratives et judiciaires autorisées.</p>
                                </li>
                                <li>
                                    <h4>Les sous-traitants techniques</h4>
                                    <p>Les  données  personnelles  des  utilisateurs  sont  accessibles  par  des  prestataires  spécialisés,  agissant  en  qualité  de sous-traitants, et spécifiquement mandatés par le CNIEL pour fournir des services techniques qui sont nécessaires au fonctionnement et à la sécurité du système Infolabo®. </p>
                                    <p>Les sous-traitants ultérieurs agissent uniquement sur instructions écrites et sont tenus à la confidentialité, ainsi qu’au strict respect des dispositions Informatique et Libertés en vigueur. Ils ne peuvent réutiliser les données personnelles auxquelles ils accèdent, ni céder ou transférer les données, à titre gratuit ou payant.</p>
                                    <p>Les moyens de traitement des sous-traitants techniques (développeur, mainteneur, hébergeur) sont localisés en France, ou dans l’Espace Economique Européen, et ils ne sont pas autorisés à transférer les données vers un pays tiers n’ayant pas un niveau de protection adéquat au sens de la réglementation Informatique et Libertés.</p>
                                    <p>Par dérogation à ce qui précède, et pour certaines technologies complémentaires nécessitant le recours à des sous-traitants  dont  les  moyens  de  traitement  sont  localisés  dans  un  pays  tiers  ne  présentant  pas  un  niveau  de  protection adéquat. Dans ce cas, le sous-traitant est soumis au respect des mêmes obligations que celles fixées aux présentes, soit  au  moyen  de  clauses  contractuelles  types,  soit  selon  les  modalités  prévues  par  décision  de  la  Commission Européenne ou tout autre dispositif équivalent reconnu par les autorités de contrôle (BCR) ou garantie appropriée telle que définie à l’article 46 du RGPD. </p>
                                    <p>La liste et les coordonnées des sous-traitants sont disponibles ci-dessous : </p>
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Nom /Coordonnées</th>
                                                    <th>Localisations</th>
                                                    <th>Nature des prestations</th>
                                                    <th>Type de garanties</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>TALAN Solutions</td>
                                                    <td>France (Amiens)</td>
                                                    <td>Développeur et mainteneur du système Infolabo®</td>
                                                    <td>Engagements contractuels (prestations de services, maintenance)</td>
                                                </tr>
                                                <tr>
                                                    <td>SAS OVH
                                                        2 rue Kellermann, 59100 Roubaix</td>
                                                    <td>France (Roubaix, Gravelines)</td>
                                                    <td>Hébergeur – Serveur dédié</td>
                                                    <td>
                                                        <a href="https://www.ovh.com/fr/support/documents_legaux/Regles_Internes_OVH.pdf ">
                                                            https://www.ovh.com/fr/support/documents_legaux/Regles_Internes_OVH.pdf
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>SAS DIGITALEO
                                                        20 avenue Jules Maniez, 35000 Rennes </td>
                                                    <td>France</td>
                                                    <td>Notifications SMS</td>
                                                    <td>
                                                        <a href="https://www.digitaleo.fr/conditions-generales-de-service">
                                                            https://www.digitaleo.fr/conditions-generales-de-service
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Google Inc.
                                                        Firebase Cloud Messaging</td>
                                                    <td>Etats-Unis</td>
                                                    <td>Notifications push sur smartphones</td>
                                                    <td>
                                                        <a href="https://support.google.com/firebase/answer/9019185?hl=fr">https://support.google.com/firebase/answer/9019185?hl=fr</a>
                                                        Privacy Shield :
                                                        <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI</a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>SAS Brevo
                                                        106 boulevard Haussmann, 75008 Paris </td>
                                                    <td>France</td>
                                                    <td>Notifications emails</td>
                                                    <td>
                                                        <a href="https://www.brevo.com/fr/legal/termsofuse/">
                                                            https://www.brevo.com/fr/legal/termsofuse/
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </li>
                            </ul>
                        </section>
                        <section>
                            <h3>Quelle est la durée de conservation des données personnelles ?</h3>
                            <p>La durée de conservation des données personnelles varie selon la finalité pour laquelle elles sont traitées :</p>
                            <ul>
                                <li>Les données de résultats sont conservées durant toute la période pendant laquelle des opérations de collecte et  d’analyses  de  lait  sont  réalisées  pour  l’utilisateur,  puis  5  ans  à  titre  de  preuve  à  compter  de  la  fin  de  ces activités ;</li>
                                <li>Les  données  d’authentification  pour  l’accès  au  compte  en  ligne  Infolabo® sont  conservées  pendant  la  durée d’utilisation, puis 5 ans après sa clôture définitive à titre de preuve ;</li>
                                <li>Les données de connexion et de navigation (logs) sont conservées pendant 12 mois glissants ;</li>
                                <li>Les cookies sont conservés au maximum pendant une durée de la session.</li>
                            </ul>
                        </section>
                        <section>
                            <h3>Quelles sont les mesures de sécurité et de protection des données personnelles ?</h3>
                            <p>Infolabo® est   un   système   d’information   sécurisé,   en   accès   restreint,   intégrant   des   mesures   techniques   et organisationnelles de protection des données conformes à la réglementation en vigueur, à l’état de l’art et aux bonnes pratiques du secteur (CNIL, ANSSI). </p>
                            <p>Les utilisateurs accèdent à Infolabo® au moyen de codes d’accès strictement personnels et confidentiels, composés d’un  identifiant  unique  et  d’un  mot  de  passe  respectant  l’état  de  l’art  en  matière  d’authentification  et  de  sécurité (robustesse, changement, renouvellement).</p>
                            <p>Les responsables conjoints de traitement et les sous-traitants ultérieurs s’engagent notamment à respecter les mesures suivantes :</p>
                            <ul>
                                <li>Prévenir toute destruction accidentelle ou illicite, perte, altération, utilisation détournée, diffusion ou accès non autorisés, ainsi que toute autre forme de traitement illicite ;</li>
                                <li>Ne  pas  divulguer,  sous  quelque  forme  que  ce  soit,  à  des  tiers  non-autorisés  tout  ou  partie  des  données personnelles ;</li>
                                <li>Ne pas traiter de données personnelles autres que celles concernées par les présentes, même si l’accès à ces données est techniquement possible ;</li>
                                <li>Veiller à ce que les personnes autorisées à traiter les données personnelles soient soumises à des obligations appropriées de confidentialité</li>
                            </ul>
                        </section>
                        <section>
                            <h3>Quels sont les droits des utilisateurs sur leurs données personnelles ?</h3>
                            <p>Tout utilisateur dont les données personnelles sont traitées par le système Infolabo® peut, à tout moment, accéder et obtenir copie de ses données, les faire rectifier ou effacer, s’opposer à leur traitement, en demander la limitation ou la portabilité.  Toutefois,  l’utilisateur  est  informé  de  l’existence  de  dérogations  pour  l’exercice  de  certains  de  ces  droits, notamment de limitation ou de suppression, qui peuvent restreindre ou empêcher l’utilisation du système Infolabo®. </p>
                            <p>Pour toute demande d’exercice de ces droits, le délégué à la protection des données du CNIEL est l’interlocuteur des utilisateurs, selon les modalités suivantes :</p>
                            <ul>
                                <li>Par courrier électronique à l’adresse suivante : <a href="mailto:donneespersonnelles@cniel.com">donneespersonnelles@cniel.com</a> ;</li>
                                <li>Par courrier postal : CNIEL – Service du DPO - 42 rue de Châteaudun – 75009 Paris.</li>
                            </ul>
                        </section>
                        <section>
                            <h3 id="cookies">Cookies</h3>
                            <h4>Qu’est-ce qu’un cookie ?</h4>
                            <p>Les cookies utilisés sont des cookies fonctionnels.
                                Les cookies fonctionnels sont indispensables au fonctionnement du système Infolabo® et à la navigation des utilisateurs sur ce dernier.
                                Ils permettent aux personnes d'utiliser les principales fonctionnalités du système et de sécuriser leur connexion.
                                Ils peuvent leur permettre également d'accéder directement à des espaces qui leur sont réservés, grâce à des identifiants ou des données qu’elles ont éventuellement antérieurement confiées au CNIEL.
                                Sans ces cookies, les utilisateurs ne pourront pas utiliser normalement Infolabo® et il est déconseillé par conséquent d’empêcher leur utilisation ou de les supprimer.</p>
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Nom du cookie</th>
                                            <th>Origine du cookie</th>
                                            <th>Finalité</th>
                                            <th>Durée de conservation</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>access_token</td>
                                            <td>Infolabo</td>
                                            <td>technique</td>
                                            <td>Jeton d'accès sécurisé, ayant une date d'expiration courte, contenant des informations d'identifications et de session, obtenu à la connexion de l'utilisateur.</td>
                                        </tr>
                                        <tr>
                                            <td>refresh_token</td>
                                            <td>Infolabo</td>
                                            <td>technique</td>
                                            <td>Jeton de rafraichissement sécurisé, ayant une date d'expiration plus longue. Permet de rafraichir le JWT sans avoir à se connecter à nouveau.</td>
                                        </tr>
                                        <tr>
                                            <td>i18nextLng</td>
                                            <td rowSpan={8}>Infolabo</td>
                                            <td rowSpan={8}>technique</td>
                                            <td rowSpan={8}>Données utiles à l'affichage de l'interface et le partage des informations à travers les différentes pages.</td>
                                        </tr>
                                        <tr><td>identifiant</td></tr>
                                        <tr><td>session</td></tr>
                                        <tr><td>per_habilitations</td></tr>
                                        <tr><td>per_personne</td></tr>
                                        <tr><td>habilitation_id</td></tr>
                                        <tr><td>habDroit</td></tr>
                                        <tr><td>displayResultsAsCharts</td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </section>
                    </div>
                </div>
            </LoginLayout>
        )
    }
}
export default withRouter(DonnesPersonnelles);
