import moment from "moment";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { CriteriaPanel, ICriteres } from "../../../components/CriteriaPanel";
import CustomComponent from "../../../components/CustomComponent";
import { FilterDropdown } from "../../../components/FilterDropdown";
import { FormDatePicker, FormInput, FormInputSelect, FormRadioBoolean } from "../../../components/FormComponents";
import { DefaultLayout } from "../../../components/Layouts";
import { SeeMoreButton } from "../../../components/Pagination";
import TABLE from "../../../components/Table";
import { Request, checkIfCriteresAreFilled, dateFormat, datePickerFormat, displayValueByStatus, hasPermission, numberFormat, onExportFile } from "../../../helpers/helpers";
import { ResultatsListHeader } from "../_common/ResultatsListHeader";
import { DailyDetailsModal } from "../_common/_DailyDetailsModal";
import { DetailsModal } from "../_common/_DetailsModal";
import { DetailsModalMensuel } from "../_common/_DetailsModalMensuel";
import { RES_TYPES_RESULTATS } from "../../../enum/res_types_resultats";
import { confirm } from 'react-confirm-box';

class ResultatsCritereList extends CustomComponent {
    private _isMounted = false
    private filterSaveKey = 'RESULTAT_EXPLOITATION_CRITERE_LIST';

    private default_filters = {
        type_resultat: RES_TYPES_RESULTATS.JOURNALIER,
        ent_id_ca: '',
        ent_id_cc: '',
        type_lait: '',
        ste_id: '',
        code_postal: '',
        ville: '',
        date_debut: moment.utc().subtract(3, 'month').startOf('day').toDate(),
        date_fin: moment.utc().endOf('day').toDate(),
        orderBy: 'ech.date_prelevement',
        ordering: 'DESC',
        criteres: [],
        groupe_fav: '',
    }

    constructor(props: any) {
        super(props)
        this.state = {
            filters: this.default_filters,
            show_more_filters: false,
            options: {
                ent_id_ca: [],
                ent_id_cc: [],
                type_lait: [],
                ste_id: [],
                villes: [],
                groupe_fav: []
            },
            litrage_lib: 'L',
            recherches: [],
            criteres: [],
            data: null,
            paginate: {
                count: 0,
                limit: 10,
                page: 0,
            },
            dailyDetailsModal: null,
            detailsModal: null,
            detailsModalMensuel: null,
            isLoading: false
        }
    }

    componentDidMount() {
        document.title = "Infolabo | Par critere";
        this._isMounted = true;
        this.getCodeUnite();
        this.getTypeLait();
        this.getEchStatus();
        this.getEntiteCCOptions();
        this.getEntiteCAOptions();
        this.getGroupeFavOptions();
        this.getRecherches();
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    getParams = (triggeredBySeemore: boolean = false) => {
        const params = new URLSearchParams();
        Object.keys(this.state.paginate).forEach(key => {
            const val = this.state.paginate[key];
            if (val !== '') params.append(key, val);
        });

        Object.keys(this.state.filters).forEach(key => {
            let val = this.state.filters[key];

            if (key === 'criteres') val = JSON.stringify(val.filter((crit: any) => !!crit.cri_id && !!crit.operator && (!!crit.value || !!crit.app_id)));
            if (val instanceof Date) val = (val as Date).toISOString();
            if (val !== '' && val !== null) params.append(key, val.toString());
        });

        params.set('page', triggeredBySeemore ? this.state.paginate.page + 1 : 0);
        return params;
    }

    getResultats = async (triggeredBySeemore: boolean, confirmAction: boolean) => {
        if (!checkIfCriteresAreFilled(this.state.filters.criteres)) return;

        const params = this.getParams(triggeredBySeemore);
        params.append('confirm', confirmAction.toString())

        const url = this.state.filters.type_resultat === RES_TYPES_RESULTATS.MENSUEL
            ? `/resultats/critere_mensuel?${params.toString()}`
            : `/resultats/critere?${params.toString()}`;

        this.setState({ isLoading: true });
        const data = await Request(url, 'GET');
        this.setState({ isLoading: false });

        // Demande de confirmation en cas de filtres ralentissant la requête
        if (data?.statusCode === 206) {
            const isConfirmed: boolean = await confirm(data.message, { labels: { confirmable: "Confirmer", cancellable: "Annuler" } });

            if (isConfirmed) {
                this.getResultats(triggeredBySeemore, true);
            }
        }

        if (data?.statusCode === 200) {
            let criteres = data.criteres;
            if (triggeredBySeemore) {
                for (const critere of this.state.criteres) {
                    let exist = false
                    for (const crit of data.criteres) {
                        if (JSON.stringify(crit) === JSON.stringify(critere)) {
                            exist = true
                        }
                    }

                    if (!exist) {
                        criteres.push(critere);
                    }
                }
                criteres.sort((a: any, b: any) => {
                    if (a.length > 1 && b.length > 1 && a[0].ordre_affichage > b[0].ordre_affichage)
                        return 1;
                    else
                        return -1
                });
            }

            this.setState((prev: any) => ({
                data: triggeredBySeemore ?
                    [...prev.data, ...(data.echantillons ? data.echantillons : data.moisFacturationsPrn)]
                    :
                    (data.echantillons ? data.echantillons : data.moisFacturationsPrn),
                criteres: criteres,
                paginate: {
                    ...prev.paginate,
                    ...data.paginate
                }
            }));
        }
    }

    getVilles(lib: string) {
        let villes: { value: string, label: string }[] = [];
        if (lib.length > 1) {
            this.request('/ref_commune/findByLib/' + lib, 'GET').then(cities => {
                cities.forEach((city: any) => {
                    villes.push({ value: city.id, label: `${city.lib_commune} (${city.departement.code_departement})` });
                })
            }).then(() => {
                this._isMounted && this.setState((prev: any) => ({
                    options: {
                        ...prev.options,
                        villes: villes
                    }
                }));
            });
        }
    }

    getTypeLait = async () => {
        const data = await this.request('/references/type_lait', 'GET')
        if (data?.statusCode === 200) {
            const type_lait: any[] = []
            for (let esp of data.especes_collectees) {
                for (let nat of data.natures_lait) {
                    type_lait.push({ label: `${esp.libelle} - ${nat.lib_court}`, value: `${esp.id}-${nat.id}` })
                }
            }
            this._isMounted && this.setState((prev: any) => ({
                options: {
                    ...prev.options,
                    type_lait
                }
            }))
        }
    }

    getEchStatus = async () => {
        const data = await this.request('/res_echantillon/status', 'GET');
        if (data && data.length) {
            const ste_id: any[] = [];
            for (const statut of data) {
                ste_id.push({ label: `${statut.libelle}`, value: `${statut.id}` });
            }
            this._isMounted && this.setState((prev: any) => ({
                options: {
                    ...prev.options,
                    ste_id
                }
            })
            );
        }
    }

    async getEntiteCCOptions(e: any | null = null) {
        let URL: string = '/ent_entite/autocomplete/CECOL'

        if (e) {
            if (e.action.action !== 'input-change') return
            URL += `?query=${e.data}`
        }

        const data = await this.request(URL, 'GET')
        if (data && data.length) {
            this._isMounted && this.setState((prev: any) => ({
                options: {
                    ...prev.options,
                    ent_id_cc: data.map((option: any) => ({ label: option.label, value: option.id }))
                }
            }));
        }
    }

    async getEntiteCAOptions(e: any | null = null) {
        let URL: string = '/ent_entite/autocomplete/CEAPP'

        if (e) {
            if (e.action.action !== 'input-change') return
            URL += `?query=${e.data}`
        }

        const data = await this.request(URL, 'GET')
        if (data && data.length) {
            this._isMounted && this.setState((prev: any) => ({
                options: {
                    ...prev.options,
                    ent_id_ca: data.map((option: any) => ({ label: option.label, value: option.id }))
                }
            }));
        }
    }

    getGroupeFavOptions = async () => {
        const data = await this.request(`/per_groupe_favori`, 'GET');
        if (data?.statusCode === 200 && this._isMounted) {
            this.setState((prev: any) => ({
                options: {
                    ...prev.options,
                    groupe_fav: data.groupes_favoris.map((gfa: any) => ({ label: gfa.nom, value: gfa.id })),
                }
            }));
        }
    }

    getRecherches = async () => {
        const recherches = await this.request(`/inf_recherche/${this.filterSaveKey}`, 'GET')

        if (recherches && this._isMounted) {
            this.setState({ recherches })
        }
    }

    handleSort(field: string) {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                orderBy: field,
                ordering: (field !== prev.filters.orderBy || prev.filters.ordering === 'DESC') ? 'ASC' : 'DESC'
            }
        }), () => this.getResultats(false, false));
    }

    async getCodeUnite() {
        let URL: string = `/ref_unite_mesure/LTR`

        const data = await this.request(URL, 'GET')
        if (data) {
            this._isMounted && this.setState((prev: any) => ({
                litrage_lib: data.lib_court || prev.litrage_lib
            }));
        }
    }

    updateThresholds = (criteres: ICriteres[]) => {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                criteres
            }
        }));
    }

    onDateClick = async (id: any) => {
        this.setState({ isLoading: true })
        this.request(`/res_echantillon/resEchantillionDetail/${id}`, 'GET').then((data) => {
            this.setState({ isLoading: false })
            if (data.statusCode === 200) {
                this.setState({
                    detailsModal: data.data,
                })
            }
        })
    }

    onDailyDetailClick = async (id: any) => {
        this.setState({ isLoading: true })
        this.request(`/res_resultat_journalier/resResultatJournalierDetail/${id}`, 'GET').then((data) => {
            this.setState({ isLoading: false })
            if (data.statusCode === 200) {
                this.setState({
                    dailyDetailsModal: data.data,
                })
            }
        })
    }

    onDateMensuelClick = async (mois_facturation: any, production_id: any) => {
        this.setState({ isLoading: true })
        const data = await this.request(`/resultats/production/${production_id}/mensuel/${mois_facturation}/detail`, 'GET')
        this.setState({ isLoading: false })
        if (data.statusCode === 200) {
            this.setState({ detailsModalMensuel: data })
        }
    }

    onTypeResChange(type: string) {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                ste_id: '',
                date_debut: type === '1'
                    ? moment.utc().subtract(3, 'month').startOf('day').toDate()
                    : moment.utc().subtract(13, 'month').startOf('day').toDate(),
                date_fin: moment.utc().endOf('day').toDate(),
                orderBy: type === '1' ? 'ech.date_prelevement' : 'rem.mois_facturation',
                ordering: 'DESC',
            },
            data: null,
        }));
    }

    onSearchLoad = (filters: any) => {
        this.getVilles(filters.ville)
        if (!this.state.options.groupe_fav.map((o: any) => o.value).includes(filters.groupe_fav)) {
            filters = { ...filters, groupe_fav: '' };
        }
        this.setState({
            filters,
            show_more_filters: filters?.criteres?.length > 0 || !!filters.date_debut || !!filters.date_fin
        }, () => this.getResultats(false, false))
    }

    onExportCsv = () => {
        const session = this.getLocalStorageSession() as any;

        const params = this.getParams();

        // journalier
        let url: string = `/exports/critere?${params.toString()}`;
        let fileName: string = `${session.ent_siret}_LISTE_ECHANTILLONS_R_${dateFormat(undefined, 'YYYYMMDD')}.csv`;

        if (this.state.filters.type_resultat === RES_TYPES_RESULTATS.MENSUEL) {
            // mensuel
            url = `/exports/critere_mensuel?${params.toString()}`;
            fileName = `${session.ent_siret}_LISTE_MENSUELS_R_${dateFormat(undefined, 'YYYYMMDD')}.csv`;
        }

        onExportFile(this, fileName, url, 'GET');
    }

    onExportPdfDetail = (mois_facturation: string, prd_siret: string, prn_id: number) => {
        const fileName: string = `${prd_siret}_DETAIL_MENSUEL_${mois_facturation}_${dateFormat(undefined, 'YYYYMMDD')}.pdf`;
        const url = `/exports/production/${prn_id}/mensuel/${mois_facturation}/detail/pdf`;

        onExportFile(this, fileName, url, 'GET');
    }

    journalierContent() {
        return (<TABLE.TBODY>
            {
                this.state.data.map((d: any) => (
                    <TABLE.TR key={d.id} title={d.prd_id && d.prd_id?.ent_id?.siret + " - " + d.prd_id?.ent_id?.nom_usuel}>
                        <TABLE.TD fixed={{ left: 0, width: 200 }} hiddenMobile>
                            {hasPermission('DRT_RES_PRODUCTION') ? (
                                <Link to={{ pathname: `/resultats/production/${d.prd_id.id}/${d.prn_id || d.id}`, search: `typeRes=${this.state.filters.type_resultat}` }} target="_blank">
                                    <strong>{d.prd_id && d.prd_id?.ent_id?.siret}<br />{d.prd_id?.ent_id?.nom_usuel}</strong>
                                </Link>
                            ) : (
                                <strong>{d.prd_id && d.prd_id?.ent_id?.siret}<br />{d.prd_id?.ent_id?.nom_usuel}</strong>
                            )}
                        </TABLE.TD>
                        <TABLE.TD fixed={{ left: 200, width: 100 }} label="Date prél.">
                            <button onClick={() => this.onDateClick(d.id)} className="link link--blue link--strong" type="button">
                                {d.date_prelevement && dateFormat(d.date_prelevement, 'DD/MM/YYYY')}
                            </button>
                        </TABLE.TD>
                        <TABLE.TD label="Type de lait">
                            {d.esp_id && d.esp_id?.libelle}
                            <br />
                            {d.nat_id && d.nat_id?.lib_court}
                        </TABLE.TD>
                        <TABLE.TD label="Statut">{d.ste_id && d.ste_id?.lib_long}</TABLE.TD>
                        <TABLE.TD label="Litrage">
                            <div className="d-flex flex-column align-items-right">
                                {numberFormat(d.volume_collecte)}
                            </div>
                        </TABLE.TD>
                        {
                            this.state.criteres.map((el: any, index: number) => {
                                const resultat = d.resultats.find((e: any) => e.cri_id?.id === el[0]?.id && e.uni_id?.id === el[1]?.id);
                                return <React.Fragment key={index}>
                                    <TABLE.TD label={(el[0]?.lib_court || "") + " " + (el[1]?.lib_court ? "(" + el[1]?.lib_court + ")" : "")}>
                                        <div className="d-flex flex-column align-items-right">
                                            {
                                                (resultat) ?
                                                    ['ANN', 'SUS'].includes(resultat.str_id?.code) ?
                                                        <>
                                                            <span>{resultat?.str_id?.libelle}</span>
                                                        </>
                                                        :
                                                        <div
                                                            {...(resultat.id && {
                                                                className: 'table-clickable-cell',
                                                                onClick: () => this.onDailyDetailClick(resultat.id)
                                                            })}
                                                            style={(['N', 'P'].includes(resultat.visibilite)) ? { fontWeight: 'bold' } : {}}
                                                        >
                                                            {
                                                                resultat.fl_pour_paiement ?
                                                                    displayValueByStatus(d.ste_id, resultat.apr_id ? resultat.apr_id?.libelle : (resultat.signe || "") + numberFormat(resultat.valeur, resultat.cri_id?.nb_decimales))
                                                                    :
                                                                    displayValueByStatus(d.ste_id, "(" + (resultat.apr_id ? resultat.apr_id?.libelle : (resultat.signe || "") + numberFormat(resultat.valeur, resultat.cri_id?.nb_decimales)) + ")")
                                                            }
                                                        </div>
                                                    :
                                                    ""
                                            }
                                        </div>
                                    </TABLE.TD>
                                    <TABLE.TD label={"Note"} className="no-box-shadow">
                                        <div className="d-flex flex-column align-items-right">
                                            {
                                                (resultat) ?
                                                    ['ANN', 'SUS'].includes(resultat.str_id?.code) ?
                                                        <>
                                                            <span>-</span>
                                                        </>
                                                        :
                                                        <div
                                                            {...(resultat.id && {
                                                                className: 'table-clickable-cell',
                                                                onClick: () => this.onDailyDetailClick(resultat.id)
                                                            })}
                                                            style={(resultat.date_prelevement && ['N', 'P'].includes(resultat.visibilite)) ? { fontWeight: 'bold' } : {}}
                                                        >
                                                            {displayValueByStatus(d.ste_id, resultat.note ? resultat.note : '-', '-')}
                                                        </div>
                                                    :
                                                    ""
                                            }
                                        </div>
                                    </TABLE.TD>
                                </React.Fragment>
                            })

                        }
                    </TABLE.TR>
                ))
            }
        </TABLE.TBODY>);
    }

    mensuelContent() {
        return (<TABLE.TBODY>
            {
                this.state.data.map((d: any, i: number) => (
                    <TABLE.TR key={i} title={d.prd_id && d.prd_id?.ent_id?.siret + " - " + d.prd_id?.ent_id?.nom_usuel}>
                        <TABLE.TD fixed={{ left: 0, width: 200 }} hiddenMobile>
                            {hasPermission('DRT_RES_PRODUCTION') ? (
                                <Link to={{ pathname: `/resultats/production/${d.prd_id.id}/${d.prn_id}`, search: `typeRes=${this.state.filters.type_resultat}` }} target="_blank">
                                    <strong>{d.prd_id && d.prd_id?.ent_id?.siret}<br />{d.prd_id?.ent_id?.nom_usuel}</strong>
                                </Link>
                            ) : (
                                <strong>{d.prd_id && d.prd_id?.ent_id?.siret}<br />{d.prd_id?.ent_id?.nom_usuel}</strong>
                            )}
                        </TABLE.TD>
                        <TABLE.TD fixed={{ left: 200, width: 100 }} label="Mois">
                            <button onClick={() => this.onDateMensuelClick(d.rem_mois_facturation, d.prn_id)} className="link link--blue link--strong" type="button">
                                {d.rem_mois_facturation && dateFormat(d.rem_mois_facturation, 'MM/YYYY', true)}
                            </button>
                        </TABLE.TD>
                        <TABLE.TD label="Type de lait">
                            {d.esp_libelle && d.esp_libelle}
                            <br />
                            {d.nat_lib_court && d.nat_lib_court}
                        </TABLE.TD>
                        <TABLE.TD label="N° tank">{d.tank_code}</TABLE.TD>
                        {
                            this.state.criteres.map((el: any, index: number) => {
                                const resultat = d.resultats.find((e: any) => e.cri_id?.id === el[0]?.id && e.uni_id?.id === el[1]?.id && e?.mois_facturation === d?.rem_mois_facturation);
                                return <React.Fragment key={index}>
                                    <TABLE.TD label={(el[0]?.lib_court || "") + " " + (el[1]?.lib_court ? "(" + el[1]?.lib_court + ")" : "")}>
                                        <div className="d-flex flex-column align-items-right">
                                            {
                                                (resultat) ?
                                                    ['ANN', 'SUS'].includes(resultat.str_id?.code) ?
                                                        <>
                                                            <span>{resultat?.str_id?.libelle}</span>
                                                        </>
                                                        :
                                                        <div
                                                            style={(['N', 'P'].includes(resultat.visibilite)) ? { fontWeight: 'bold' } : {}}
                                                        >
                                                            {
                                                                resultat.fl_pour_paiement ?
                                                                    displayValueByStatus(d.ste_id, resultat.apr_id ? resultat.apr_id?.libelle : (resultat.signe || "") + numberFormat(resultat.valeur, resultat.cri_id?.nb_decimales))
                                                                    :
                                                                    displayValueByStatus(d.ste_id, "(" + (resultat.apr_id ? resultat.apr_id?.libelle : (resultat.signe || "") + numberFormat(resultat.valeur, resultat.cri_id?.nb_decimales)) + ")")
                                                            }
                                                        </div>
                                                    :
                                                    ""
                                            }
                                        </div>
                                    </TABLE.TD>
                                    <TABLE.TD label={"Note"} className="no-box-shadow">
                                        <div className="d-flex flex-column align-items-right">
                                            {
                                                (resultat) ?
                                                    ['ANN', 'SUS'].includes(resultat.str_id?.code) ?
                                                        <>
                                                            <span>-</span>
                                                        </>
                                                        :
                                                        <div
                                                            style={(resultat.date_prelevement && ['N', 'P'].includes(resultat.visibilite)) ? { fontWeight: 'bold' } : {}}
                                                        >
                                                            {displayValueByStatus(d.ste_id, resultat.note ? resultat.note : '-', '-')}
                                                        </div>
                                                    :
                                                    ""
                                            }
                                        </div>
                                    </TABLE.TD>
                                </React.Fragment>
                            })

                        }
                    </TABLE.TR>
                ))
            }
        </TABLE.TBODY>);
    }

    render() {
        const type_res = this.state.filters?.type_resultat === RES_TYPES_RESULTATS.JOURNALIER ? 'ech' : 'rem';
        return (
            <DefaultLayout loading={this.state.isLoading}>
                <div className="container">
                    <ResultatsListHeader />
                    <FilterDropdown
                        startOpen={true}
                        onExport={{ csv: this.onExportCsv }}
                        parent={this}
                        type_recherche={this.filterSaveKey}
                        filters={this.state.filters}
                        recherches={this.state.recherches}
                        onUpdate={this.getRecherches}
                        onSearchLoad={this.onSearchLoad}
                        onApplyFilters={() => this.getResultats(false, false)}
                        onFiltersSearch={() => this.getResultats(false, false)}
                        onFiltersReset={() => {
                            this.setState((prev: any) => ({
                                filters: this.default_filters,
                                options: { ...prev.options, villes: [] }
                            }), () => {
                                this.getEntiteCAOptions();
                                this.getEntiteCCOptions();
                            })
                        }}>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group form-group--inline">
                                    <FormRadioBoolean
                                        label={"Type de resultat"}
                                        id="type_resultat"
                                        name="type_resultat"
                                        trueLabel="Résultats journaliers"
                                        falseLabel="Résultats mensuels"
                                        handle={(e: any) => {
                                            this.handleInputChange(e, null, 'filters');
                                            this.onTypeResChange(e.target.value);
                                        }}
                                        value={this.state.filters?.type_resultat || RES_TYPES_RESULTATS.JOURNALIER}
                                        isSubmitted={this.state.isSubmitted}
                                    />
                                </div>
                                <div className="form-group form-group--inline">
                                    <FormInputSelect isClearable label="Centre d’appartenance" name={`ent_id_ca`} value={this.state.filters.ent_id_ca} handle={(e: any) => this.handleInputChange(e, null, 'filters')} handleInput={(e: any) => this.getEntiteCAOptions(e)} options={this.state.options.ent_id_ca} />
                                </div>
                                <div className="form-group form-group--inline">
                                    <FormInputSelect isClearable label="Centre de collecte" name={`ent_id_cc`} value={this.state.filters.ent_id_cc} handle={(e: any) => this.handleInputChange(e, null, 'filters')} handleInput={(e: any) => this.getEntiteCCOptions(e)} options={this.state.options.ent_id_cc} />
                                </div>
                                <div className="form-group form-group--inline">
                                    <FormInputSelect required isClearable label="Type de lait" name={`type_lait`} value={this.state.filters.type_lait} handle={(e: any) => this.handleInputChange(e, null, 'filters')} options={this.state.options.type_lait} />
                                </div>
                                <div className="form-group form-group--inline">
                                    <FormInputSelect
                                        isClearable
                                        //disabled={this.state.filters?.type_resultat !== RES_TYPES_RESULTATS.JOURNALIER}
                                        disabled={true}
                                        title={this.state.filters?.type_resultat === RES_TYPES_RESULTATS.JOURNALIER ? "Fonctionnalité prochainement disponible." : ""}
                                        label="Statut de l'échantillon" name={`ste_id`}
                                        value={this.state.filters.ste_id}
                                        handle={(e: any) => this.handleInputChange(e, null, 'filters')}
                                        options={this.state.options.ste_id} />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group form-group--inline">
                                    <FormInput label="Code postal" name={`code_postal`} value={this.state.filters.code_postal} handle={(e: any) => this.handleInputChange(e, null, 'filters')} />
                                </div>
                                <div className="form-group form-group--inline">
                                    <FormInputSelect isClearable label="Ville" name={`ville`}
                                        value={this.state.filters.ville}
                                        handle={(e: any) => this.handleInputChange(e, null, 'filters')}
                                        handleInput={(e: any) => this.getVilles(e.data)}
                                        options={this.state.options.villes}
                                        disabledDropdownWhenEmpty={true}
                                        placeholder={''}
                                    />
                                </div>
                                <div className="form-group form-group--inline">
                                    <FormInputSelect isClearable label="Groupe de favoris" name={`groupe_fav`}
                                        value={this.state.filters.groupe_fav}
                                        handle={(e: any) => this.handleInputChange(e, null, 'filters')}
                                        options={this.state.options.groupe_fav}
                                    />
                                </div>
                                <div className="form-group-flex">
                                    <div className="form-group form-group--inline">
                                        <FormDatePicker
                                            name="date_debut"
                                            type="text"
                                            handle={(e: any) => this.handleInputChange(e, null, 'filters', () => console.log(this.state.filters))}
                                            value={datePickerFormat(this.state.filters.date_debut)}
                                            label="Période du"
                                            month={this.state.filters?.type_resultat === RES_TYPES_RESULTATS.MENSUEL}
                                        />
                                    </div>
                                    <div className="form-group form-group--inline">
                                        <FormDatePicker
                                            name="date_fin"
                                            type="text"
                                            handle={(e: any) => this.handleInputChange(e, null, 'filters')}
                                            value={datePickerFormat(this.state.filters.date_fin)}
                                            label="au"
                                            month={this.state.filters?.type_resultat === RES_TYPES_RESULTATS.MENSUEL}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <CriteriaPanel
                                    criteres={this.state.filters.criteres}
                                    onChange={this.updateThresholds}
                                    min={1}
                                    togglable
                                    open={this.state.show_more_filters}
                                    onToggle={(open: boolean) => this.setState({ show_more_filters: open })} />
                            </div>
                        </div>
                    </FilterDropdown>

                    {!this.state.data && <TABLE.NO_REQUEST />}
                    {this.state.data && (<>
                        {this.state.data.length <= 0 ? <TABLE.NO_RESULT /> : (<>
                            {/* <TABLE responsive="card" fixed theadSticky> */}
                            <TABLE responsive="card" fixed bordered>
                                <TABLE.THEAD>
                                    <TABLE.TR>
                                        <TABLE.TH name={type_res === "rem" ? "prd_entite.nom_usuel" : 'ent_prd.nom_usuel'} fixed={{ left: 0, width: 200 }}
                                            label="Producteur"
                                            sort={{ orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort(type_res === "rem" ? "prd_entite.nom_usuel" : 'ent_prd.nom_usuel') }} />
                                        {
                                            this.state.filters?.type_resultat === RES_TYPES_RESULTATS.JOURNALIER ?
                                                <TABLE.TH name='ech.date_prelevement' fixed={{ left: 200, width: 100 }}
                                                    label="Date prél."
                                                    sort={{ orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('ech.date_prelevement') }} />
                                                :
                                                <TABLE.TH name='rem.mois_facturation' fixed={{ left: 200, width: 100 }}
                                                    label="Mois"
                                                    sort={{ orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('rem.mois_facturation') }} />
                                        }
                                        <TABLE.TH name='nat.libelle'
                                            label="Type de lait"
                                            sort={{ orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('nat.libelle') }} />
                                        {
                                            this.state.filters?.type_resultat === RES_TYPES_RESULTATS.JOURNALIER ?
                                                <>
                                                    <TABLE.TH name='status_ech.lib_long'
                                                        label="Statut"
                                                        sort={{ orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('status_ech.lib_long') }} />
                                                    <TABLE.TH name={type_res + '.volume_collecte'}
                                                        label={"Litrage (" + this.state.litrage_lib + ")"}
                                                        sort={{ orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('ech.volume_collecte') }} />
                                                </>
                                                :
                                                <TABLE.TH name='prn.tank_code'
                                                    label="N° tank"
                                                    sort={{ orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('prn.tank_code') }} />
                                        }
                                        {
                                            this.state.criteres.map((el: any, index: number) =>
                                                <React.Fragment key={index}>
                                                    <TABLE.TH>
                                                        <div className="d-flex flex-column align-items-center">
                                                            <strong>{(el[0]?.lib_court || "")}</strong>
                                                            <small>{(el[1]?.lib_court ? "(" + el[1]?.lib_court + ")" : "")}</small>
                                                        </div>

                                                    </TABLE.TH>
                                                    <TABLE.TH className="no-box-shadow" name=''
                                                        label={"Note"}
                                                    />
                                                </React.Fragment>
                                            )
                                        }
                                    </TABLE.TR>
                                </TABLE.THEAD>
                                {this.state.filters?.type_resultat === RES_TYPES_RESULTATS.JOURNALIER ? this.journalierContent() : this.mensuelContent()}
                            </TABLE>
                            <SeeMoreButton behaviour="scroll-mobile" paginate={this.state.paginate} onClick={() => this.getResultats(true, true)} disabled={this.state.isLoading} />
                        </>)}
                    </>)}
                    {this.state.detailsModal && (
                        <DetailsModal echDetails={this.state.detailsModal} onClose={() => this.setState({ detailsModal: null })} />
                    )}
                    {this.state.dailyDetailsModal && (
                        <DailyDetailsModal dailyDetails={this.state.dailyDetailsModal} onClose={() => this.setState({ dailyDetailsModal: null })} />
                    )}
                    {this.state.detailsModalMensuel && (
                        <DetailsModalMensuel mensuelDetail={this.state.detailsModalMensuel} onExportPdf={this.onExportPdfDetail} onClose={() => this.setState({ detailsModalMensuel: null })} />
                    )}
                </div>
            </DefaultLayout>
        )
    }
}

export default withTranslation()(withRouter(ResultatsCritereList));
